<template>
  <div class="content users-content">
    <AdminHeader />
    <div class="search">
      <!-- <div class="search-input">
        <input type="text" placeholder="Digite sua busca" v-model="searchQuery" @keyup.enter="searchOrders">
      </div> -->
      <button class="main-btn create-order" @click="showNewPromoModal = true; addBodyClass()">
        <p>Criar nova</p>
        <div></div>
      </button>
    </div>
    <div class="table-main table-list">
      <table class="table-separate">
        <tr>
          <!--<th>
            <input type="checkbox" name="order-check" id="order-check" class="check-all">
          </th> -->
          <th @click="sort('status')">
            <p>Status</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('name')">
            <p>Nome</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('discount')">
            <p>Percentual</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('start-at')">
            <p>Data de início</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('end-at')">
            <p>Data de fim</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </tr>
        <tr v-for="promo in promos" v-bind:key="promo.id">
          <!--<td>
            <input type="checkbox" name="order-check" id="order-check" class="check-one">
          </td>-->
          <td>
            <div class="status-wrap" :class="promo.attributes.trashed ? 'inactive' : 'active'">
              <div class="status">
                {{promo.attributes.trashed ? 'Inativa' : 'Ativa'}}
              </div>
            </div>
          </td>
          <td v-if="promo.attributes.trashed">
              <span>
                {{ promo.attributes.name }}
              </span>
          </td>
          <td v-else class="link-over" @click="selectedPromo = promo, showViewPromoModal = true; addBodyClass()">
            <a>{{ promo.attributes.name }}</a>
          </td>
          <td>
            <p>+{{ promo.attributes.discount * 100 }}% OFF</p>
          </td>
          <td>
            <p>{{ $formattedDateWithoutHours(promo.attributes.startAt) }}</p>
            <span>{{ $formattedDateOnlyHours(promo.attributes.startAt) }}</span>
          </td>
          <td>
            <p>{{ $formattedDateWithoutHours(promo.attributes.endAt) }}</p>
            <span>{{ $formattedDateOnlyHours(promo.attributes.endAt) }}</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>
  <ModalCreatePromo  :show="showNewPromoModal"  @close="showNewPromoModal = false" />
  <ModalEditPromo :show="showEditPromoModal" @close="showEditPromoModal = false" :promo_data="selectedPromo" />
  <ModalViewPromo :show="showViewPromoModal" @close="showViewPromoModal = false" @edit="openEditModal" :promo_data="selectedPromo" />
</template>
<script>
import Footer from '@/components/Footer.vue'
import AdminHeader from '@/components/admin/AdminHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import ModalCreatePromo from '../../components/admin/ModalCreatePromo.vue'
import ModalEditPromo from '../../components/admin/ModalEditPromo.vue'
import ModalViewPromo from '../../components/admin/ModalViewPromo.vue'

export default {
  // props: ['hunts'],
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      sortColumn: null,
      sortDirection: 'asc',
      showNewPromoModal: false,
      showViewPromoModal: false,
      showEditPromoModal: false,
      selectedPromo: []
    }
  },
  components: { AdminHeader, ModalCreatePromo, ModalEditPromo, ModalViewPromo, Footer },
  computed: {
    ...mapGetters(['promos', 'total_pages'])
    // ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['adminFetchPromos']),
    changePage (page) {
      this.currentPage = page
      this.adminFetchPromos({ page: this.currentPage })
    },
    openEditModal () {
      this.showViewPromoModal = false // Fechar o modal de visualização
      this.showEditPromoModal = true // Abrir o modal de edição
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.adminFetchPromos({ page: this.currentPage, sort: this.sortColumn, direction: this.sortDirection })
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    }
  },
  created () {
    this.adminFetchPromos({ page: this.currentPage, sort: this.sortColumn, direction: this.sortDirection })
  },
  watch: {
    total_pages (newVal, oldVal) {
      if (newVal < oldVal) {
        // Se new value é menor que o old value, resete para o old value
        this.$nextTick(() => {
          this.$store.commit('SET_TOTAL_PAGES', oldVal)
        })
      }
    },
    showEditPromoModal (newVal, oldVal) {
      console.log('showEditPromoModal alterado de', oldVal, 'para', newVal)
      // Qualquer lógica adicional que você deseja executar quando showEditPromoModal é alterado
    }
  }
}
</script>
