<template>
  <div v-if="show" class="modal">
    <div class="modal-content popup-detail edit-order data form">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title">
        <h1>Criar compensação</h1>
      </div>
      <div class="input-flex">
        <div class="input-wrap double">
          <label>Valor</label>
          <input v-mask="['#', ',##', '#,##', '##,##', '###,##', '####,##', '#####,##', '######,##', '#######,##', '########,##']" type="text" placeholder="Digite aqui" v-model="compensation.amount" />
        </div>
        <div class="input-wrap double">
          <label>Distribuidor</label>
          <select v-model="compensation.user">
            <option value="" disabled selected>Selecione aqui</option>
            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.attributes.name }}</option>
          </select>
        </div>
      </div>
      <div class="input-wrap">
        <label>Tipo</label>
        <select v-model="compensation.type">
          <option value="" disabled selected>Selecione aqui</option>
          <option value="positive">Adição</option>
          <option value="negative">Subtração</option>
        </select>
      </div>
      <div class="input-wrap">
        <label>Motivo</label>
        <input type="text" placeholder="Digite aqui" v-model="compensation.reason" />
      </div>
      <button class="main-btn create-order" @click="createCompensation">Salvar</button>
  </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../config/axiosConfig.js'
export default {
  props: ['show'],
  data () {
    return {
      compensation: {
        amount: '',
        user: '',
        reason: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapGetters(['users'])
  },
  methods: {
    ...mapActions(['setLoadingState', 'adminFetchDistributor', 'adminFetchCompensations']),
    closeModal () {
      this.$emit('close')
      this.compensation.amount = ''
      this.compensation.user = ''
      this.compensation.reason = ''
      this.compensation.type = ''
    },
    async createCompensation () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        if (this.compensation.type === 'negative') {
          formData.append('amount', '-' + this.compensation.amount.replace(/,/g, ''))
        } else {
          formData.append('amount', this.compensation.amount.replace(/,/g, ''))
        }
        formData.append('user', this.compensation.user)
        formData.append('reason', this.compensation.reason)
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/compensations`, formData)
        this.$notification.emit('flash', { message: 'Compensação criada', type: 'success' })
        this.compensation = []
        this.closeModal()
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao criar a compensação', type: 'error' })
      } finally {
        this.adminFetchCompensations({ page: 1 })
        this.setLoadingState(false)
      }
    }
  },
  created () {
    this.adminFetchDistributor({ page: 1, page_size: 10 })
  }
}
</script>
