<template>
  <div class="table-main dash-table dash-orders">
    <h2>Usuários</h2>
    <div class="table-wrap">
      <p class="sub-title">Recentes</p>
      <table>
          <tr v-for="user in users" :key="user.id" @click="openUserDetails(user)">
            <td class="orders-number users-names" >
              <div class="status-wrap sketch"><div :class="['status', user.attributes.status]"></div></div>
              <p>{{ user.attributes.name }}</p>
            </td>
            <td class="date-time">
              <p v-if="user.attributes.lastLoginAt === null">
                Nunca logou
              </p>
              <p v-else>
                Logou em {{ $formattedDateWithoutHours(user.attributes.lastLoginAt) }}
              </p>
            </td>
          </tr>
      </table>
      <!--<div class="buttons-wrap">
        <button class="main-btn create-order" @click="showModalCreateUser = true">
            <p>Criar novo</p>
            <div></div>
        </button>
        <button class="main-btn grey-btn see-orders">Ver tudo</button>
      </div>-->
  </div>
  <ModalUserEdit :show="showModalUserEdit" @close="showModalUserEdit = false" :user_data="selectedUser"/>
  <ModalCreateUser :show="showModalCreateUser" @close="showModalCreateUser = false" @submit="createOrder" />
  <ModalUserDetail :show="showUserDetailsModal" :user_data="selectedUser" @close="showUserDetailsModal = false" @edit="openUserEditFromDetails"/>
</div>
</template>

<script>
import ModalCreateUser from './ModalCreateUser.vue'
import ModalUserEdit from './ModalUserEdit.vue'
import { mapGetters, mapActions } from 'vuex'
import ModalUserDetail from './ModalUserDetail.vue'

export default {
  data () {
    return {
      showModalCreateUser: false,
      showUserDetailsModal: false,
      showModalUserEdit: false,
      selectedUser: null
    }
  },
  computed: {
    ...mapGetters(['users'])
  },
  methods: {
    ...mapActions(['adminFetchUsers']),
    openUserDetails (user) {
      this.selectedUser = user
      this.showUserDetailsModal = true
    },
    openUserEdit (user) {
      this.selectedUser = user
      this.showModalUserEdit = true
    },
    openUserEditFromDetails () {
      this.showUserDetailsModal = false
      this.showModalUserEdit = true
    }
  },
  created () {
    this.adminFetchUsers({ page: 1, page_size: 10 })
  },
  components: { ModalCreateUser, ModalUserDetail, ModalUserEdit }
}
</script>
