<template>
    <section class="popup recoverpassword">
        <div class="logo">
            <div></div>
        </div>
        <div class="title-wrap">
            <h1>Redefina sua senha</h1>
            <p>Não se preocupe. Recupere o acesso à sua conta clicando no link de ativação enviado em sua caixa de mensagens.</p>
        </div>
        <div class="form-password">
            <form action="" id="form-password" @submit.prevent="submitForm">
                <div class="input-wrap">
                    <label for="email">E-mail</label>
                    <input type="email" class="email" v-model="email" required placeholder="Digite seu email">
                </div>
            </form>
            <router-link class="main-link to-login" to="/login">Voltar para a página de Login</router-link>
        </div>
        <button class="main-btn send-email" @click="submitForm">Enviar e-mail</button>
    </section>
    <section class="hidden-content">
      <div class="hidden-content-header">
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="hidden-content-content">
        <div class="hidden-content-content-left">
          <div class="hidden-content-content-left-block">
            <span></span>
            <span></span>
          </div>
          <div class="hidden-content-content-left-block">
            <span></span>
            <span></span>
          </div>
          <div class="hidden-content-content-left-block">
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="hidden-content-content-right">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
    <!-- <div class="reset-password">
      <h1>Recuperar Senha</h1>
      <form @submit.prevent="submitForm">
        <label>
          Email:
          <input v-model="email" type="email" required />
        </label>
        <button type="submit">Enviar Link de Recuperação</button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div> -->
</template>

<script>
import axios from '../config/axiosConfig.js'
import router from '../router.js'
export default {
  data () {
    return {
      email: '',
      message: ''
    }
  },
  methods: {

    submitForm () {
      const formData = new FormData()

      formData.append('email', this.email)
      axios.post(`${process.env.VUE_APP_API_URL}/api/auth/mail/recover`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(resp => {
          this.$notification.emit('flash', {
            message: 'E-mail de recuperação de senha enviado.',
            type: 'success'
          })
          router.push('/login')
          // this.message = 'Email de recuperação de senha enviado.'
        }).catch(error => {
          this.$notification.emit('flash', {
            message: 'Ocorreu um erro ao iniciar a recuperação de senha.',
            type: 'error'
          })
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.reset-password {
  width: 300px;
  margin: 0 auto;
}

.reset-password h1 {
  text-align: center;
}

.reset-password form {
  display: flex;
  flex-direction: column;
}

.reset-password form label {
  margin-bottom: 10px;
}

.reset-password form input {
  margin-bottom: 20px;
}

.reset-password form button {
  cursor: pointer;
}
</style>
