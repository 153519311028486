<template>
  <div v-if="show" class="modal">
    <section class="popup-detail user summary view">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title">
        <div class="title-wrap">
          <div class="name-status">
            <h1>{{ user.name }} #{{ user.id }}</h1>
            <div class="status-wrap" :class="user.status">
              <span>
                {{ user.status === 'active' ? 'Ativo' : user.status === 'inactive' ? 'Inativo' : '' }}
              </span>
            </div>
          </div>
        </div>
        <ul class="breadcrumb">
          <li>
            <p v-if="user.role === 'client'">
              Distribuidor
            </p>
            <p v-if="user.role === 'meeg'">
              Super Administrador
            </p>
            <p v-if="user.role === 'admin'">
              Administrador
            </p>
            <p v-if="user.role === 'logistic'">
              Logística
            </p>
          </li>
        </ul>
      </div>
      <div class="user-details details">
        <div class="user-data details-simple">
          <div class="email">
            <p>E-mail</p>
            <span>{{ user.email }}</span>
          </div>
        </div>
      </div>
      <div v-if="user.role === 'client'" class="pred-details details">
        <div class="pred-data details-flex">
          <div>
            <p>Loja</p>
            <span>{{ user.stores[0].attributes.name }} ({{ user.stores[0].attributes.cnpj }}) ERP {{ user.stores[0].attributes.codeErp }}</span>
          </div>
        </div>
        <div class="pred-data details-flex">
          <div>
            <p>Desconto por pedido</p>
            <span>{{ user.discounts.current * 100 }}%</span>
          </div>
        </div>
        <div class="pred-data details-flex">
          <div>
            <p>Desconto médio aplicado</p>
            <span>{{ user.discounts.average * 100 }}%</span>
          </div>
        </div>
        <div class="pred-data details-flex">
          <div>
            <p>Tabela de preços</p>
            <span>{{ user.relationships.price.attributes.name }}</span>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <button  v-if="user.status === 'inactive'" class="main-link tooltip right" @click="sendInvite()">ENVIAR CONVITE
          <span class="tooltip-text">Tem certeza de que deseja enviar o convite via e-mail para este usuário ter acesso à
            plataforma? <span>Essa ação não poderá ser desfeita.</span>
            <div class="arrow"></div>
          </span>
        </button>
        <button class="main-btn edit" @click="$emit('edit')">Editar</button>
      </div>

    </section>
  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'

export default {
  data () {
    return {
      user: {
        name: '',
        email: '',
        role: '',
        price_list_id: '',
        discounts: {
          // main_discount: '',
          current_discount: ''
        },
        stores: []
      }
    }
  },
  props: ['show', 'user_data'],
  watch: {
    show: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.fetchUser()
        }
      }
    }
  },
  methods: {
    async fetchUser () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/users/${this.user_data.id}`)
        this.user = response.data.data.attributes
        this.user.id = response.data.data.id
        this.user.stores = response.data.data.relationships.stores.data
        this.user.relationships = response.data.data.relationships
      } catch (error) {
        console.error(error)
      }
    },
    // formatCnpj (cnpj) {
    //   if (cnpj) {
    //     const cnpjString = cnpj.toString()
    //     // 00.000.000/0000-00
    //     return (
    //       cnpjString.substr(0, 2) +
    //       '.' +
    //       cnpjString.substr(2, 3) +
    //       '.' +
    //       cnpjString.substr(5, 3) +
    //       '/' +
    //       cnpjString.substr(8, 4) +
    //       '-' +
    //       cnpjString.substr(12, 2)
    //     )
    //   }
    //   return ''
    // },
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    sendInvite () {
      axios.post(`${process.env.VUE_APP_API_URL}/api/admin/users/${this.user.id}/mail/invite`)
        .then(response => {
          alert('Convite enviado')
        })
        .catch(error => {
          alert('Erro ao enviar o convite')
          console.log(error)
        })
    }
  }
}
</script>
