<template>
  <div v-if="isModalOpened" class="modal">
  <section class="popup-detail upload-nota" :class="{ opened: isModalOpened }">
    <span class="close small-btn close" @click="closeModal">&times;</span>
    <h1>Nova nota</h1>
    <p>
      Sua nota fiscal foi submetida e pudemos identificar alguns dos produtos que fazem parte dela. Verifique as correlações e corrija-as se necessário.
    </p>
    <div class="popup-table itens-table">
      <table>
        <thead>
          <tr>
            <th>
              <p></p>
            </th>
            <th>
              <p>Na sua nota</p>
            </th>
            <th>
              <p>No sistema Antares</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="nfe_item in this.nfe_items" :key="nfe_item.quantity">
            <td>
              <div v-if="nfe_item.relationships.userItem !== null" class="status-wrap approved"></div>
              <div v-if="nfe_item.relationships.userItem == null" class="status-wrap pending"></div>
            </td>
            <td>
              <p>{{nfe_item.code}} <br> {{ nfe_item.description }}</p>
            </td>
            <td class="select-relationship">
              <template v-if="!nfe_item.relationships.userItem || !nfe_item.relationships.userItem.relationships.item">
                <VueMultiselect
                  v-model="selectedOptions[nfe_item.code]"
                  :options="itemsList"
                  :close-on-select="true"
                  :clear-on-select="true"
                  @select="addItemRelationShip(nfe_item, $event)"
                  placeholder="Item"
                  label="name"
                  track-by="id"
                  :object="true"
                  :allow-empty="false"
                  @search-change="asyncSearchItems"
                />
              </template>
              <template v-else>
                <p>
                  {{nfe_item.relationships.userItem.relationships.item.attributes.name}}
                </p>
              </template>
                <button class="read-not" v-if="nfe_item.relationships.userItem == null" v-on:click="removeNfeItem(nfe_item)">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pre>
    </pre>
    <div class="payment-detail" v-if="nfeData">
      <div class="total-wrap">
        <div class="total">
          <p>Total</p>
          <div>
            <h3>R$ {{ this.nfeData.attributes.amount / 100 }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <!--
      <router-link class="main-btn grey-btn see-hunts" to="/products">
        Editar lista dos meus produtos
      </router-link>
      -->
      <button class="main-btn" :disabled="disableSubmit()" v-on:click="saveUserItemsRelationships">Confirmar códigos</button>
    </div>
  </section>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../config/axiosConfig.js'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    VueMultiselect
  },
  data () {
    return {
      uploadedMedia: [],
      uploadSuccess: '',
      userItemsRelationships: {},
      selectedOptions: {},
      isModalOpened: false
    }
  },
  watch: {
    modal_set_item_relationship_opened: {
      immediate: true,
      handler (newVal, oldVal) {
        this.isModalOpened = newVal
      }
    },
    nfe_items: {
      immediate: true,
      handler (newVal, oldVal) {
        this.disableSubmit()
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'items', 'nfe_items', 'modal_set_item_relationship_opened']),

    itemsList () {
      return this.items.map(x => {
        return {
          id: x.relationships.item.id,
          name: x.relationships.item.attributes.name,
          ...x
        }
      })
    }
  },
  created () {
    this.fetchItems({ query: '' })
    this.disableSubmit()
  },
  mounted () {
    this.fetchItems({ query: '' })
  },
  methods: {
    ...mapActions(['setLoadingState', 'setModalSetItemRelationshipOpened', 'fetchItems', 'setNfeItems', 'setModalUploadInvoiceOpened']),
    closeModal () {
      this.setModalSetItemRelationshipOpened(false)
      this.setModalUploadInvoiceOpened(true)
    },
    disableSubmit () {
      return !this.nfe_items.every(item => item.relationships.userItem !== null)
    },
    addItemRelationShip (userItem, item) {
      const itemIndex = this.nfe_items.findIndex((nfeItem) => nfeItem.code === userItem.code)
      console.log('item', item)
      if (itemIndex !== -1) {
        this.nfe_items[itemIndex].relationships = {
          userItem: {
            attributes: {
              name: userItem.description
            },
            relationships: {
              addedItem: item
            }
          }
        }
      }
      this.disableSubmit()
    },
    asyncSearchItems (query) {
      this.setLoadingState(true)
      this.fetchItems({ query: query })
      this.setLoadingState(false)
    },
    // removeNfeItem (item) {
    //   const index = this.nfe_items.findIndex(nfeItem => nfeItem.code === item.code)
    //   if (index !== -1) {
    //     this.nfe_items.splice(index, 1)
    //   }
    // },
    removeNfeItem (item) {
      const index = this.nfe_items.findIndex(nfeItem => {
        console.log(`nfeItem.code é ${nfeItem.code} e item.code é ${item.code}`)
        return nfeItem.code === item.code
      })

      if (index !== -1) {
        this.nfe_items.splice(index, 1)
        console.log(`Item removido: ${item.code}`)
      } else {
        console.error('Item não encontrado para remoção:', item)
      }
    },
    async saveUserItemsRelationships () {
      const userId = this.user.id

      const itemsToAdd = this.nfe_items.filter(nfeItem =>
        nfeItem.relationships.userItem &&
        nfeItem.relationships.userItem.relationships.addedItem
      )

      const requests = []

      for (const nfeItem of itemsToAdd) {
      // for (const nfeItem of this.nfe_items) {
        try {
          const formData = {
            item_id: nfeItem.relationships.userItem.relationships.addedItem.relationships.item.id,
            code: nfeItem.code,
            name: nfeItem.relationships.userItem.attributes.name,
            user_id: userId
          }

          const url = `${process.env.VUE_APP_API_URL}/api/items/codes`

          requests.push(axios.post(url, formData))
        } catch (e) {
          console.error(e)
        }
      }

      try {
        await Promise.all(requests)

        this.nfe_items.forEach((nfeItem) => {
          if (nfeItem.relationships.userItem && nfeItem.relationships.userItem.relationships.addedItem) {
            nfeItem.relationships.userItem.relationships.item = nfeItem.relationships.userItem.relationships.addedItem
            delete nfeItem.relationships.userItem.relationships.addedItem
          }
        })
        this.setNfeItems(this.nfe_items)
        this.closeModal()
        this.$notification.emit('flash', { message: 'Todos os relacionamentos salvos com sucesso', type: 'success' })
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao salvar os relacionamentos', type: 'error' })
      }
    }
  }
}
</script>
