<template>
  <section class="popup define">
    <div class="logo">
      <div></div>
    </div>
    <div class="title-wrap">
      <h1>Acesse sua conta</h1>
      <p>Agora basta preencher a nova senha nos campos abaixo para poder acessar a plataforma.</p>
    </div>
    <div class="form-password">
      <form action="" id="form-password">
        <div class="input-wrap pass-first">
          <div class="eye-pass" :class="{ show: passwordFieldType === 'text' }" @click="togglePasswordVisibility"></div>
          <label for="password-first">Nova senha</label>
          <input v-model="password" @input="validatePassword" :type="passwordFieldType" class="pass" name="password-first" id="password-first" placeholder="Digite a nova senha">
          <div class="pass-strength">
            <div class="pass-status check-one" :class="{ checked: password.length >= 8 }">
              <div></div>
              <p>Pelo menos 8 caracteres</p>
            </div>
            <div class="pass-status check-two" :class="{ checked: /\d/.test(password) }">
              <div></div>
              <p>Pelo menos um numeral</p>
            </div>
            <div class="pass-status check-three" :class="{ checked: /[!@#$%^&*]/.test(password) }">
              <div></div>
              <p>Pelo menos um caractere especial</p>
            </div>
          </div>
        </div>
        <div class="input-wrap">
          <div class="eye-pass" :class="{ show: newPasswordFieldType === 'text' }" @click="toggleNewPasswordVisibility"></div>
          <label for="password-second">Repita a nova senha</label>
          <input v-model="passwordConfirm" @input="validatePassword" :type="newPasswordFieldType" class="pass" name="password-second" id="password-second"
            placeholder="Digite novamente a senha">
        </div>
      </form>
    </div>
    <a class="main-btn define" href="" :disabled="!isPasswordValid" @click.prevent="submitPassword">Definir senha</a>
  </section>
  <section class="hidden-content">
    <div class="hidden-content-header">
      <div>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="hidden-content-content">
      <div class="hidden-content-content-left">
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="hidden-content-content-right">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      password: '',
      passwordConfirm: '',
      isPasswordValid: false,
      message: '',
      passwordFieldType: 'password',
      newPasswordFieldType: 'password'
    }
  },
  methods: {
    validatePassword () {
      const hasEightCharacters = this.password.length >= 8
      const hasNumber = /\d/.test(this.password)
      const hasSpecialCharacter = /[!@#$%^&*]/.test(this.password)
      this.isPasswordValid = hasEightCharacters && hasNumber && hasSpecialCharacter
      // return hasEightCharacters && hasNumber && hasSpecialCharacter
    },
    submitPassword () {
      if (this.password !== this.passwordConfirm) {
        this.$notification.emit('flash', {
          message: 'As senhas não coincidem.',
          type: 'error'
        })
        return
      }
      const formData = new FormData()

      formData.append('token', this.$route.query.token)
      formData.append('password', this.password)
      if (this.isPasswordValid) {
        axios.post(`${process.env.VUE_APP_API_URL}/api/auth/activate`, formData)
          .then(response => {
            this.$notification.emit('flash', {
              message: 'Senha definida com sucesso.',
              type: 'success'
            })
            this.$router.push('/login')
          })
          .catch(error => {
            this.$notification.emit('flash', {
              message: 'Ocorreu um erro ao definir a senha.',
              type: 'error'
            })
            // this.$router.push('/login')
            console.log(error)
          })
      }
    },
    togglePasswordVisibility () {
      this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text'
    },
    toggleNewPasswordVisibility () {
      this.newPasswordFieldType = (this.newPasswordFieldType === 'text') ? 'password' : 'text'
    }
  }
}
</script>
