<template>
  <div>
    <VueMultiselect
      v-model="selectedOption"
      :options="options"
      :close-on-select="true"
      :clear-on-select="true"
      @select="addItemToOrder"
      placeholder="Item"
      label="name"
      track-by="name"
      @search-change="asyncFind"
    >
      <template v-slot:noResult>
        <span v-if="loading">Buscando...</span>
        <span v-else>Nenhum item encontrado</span>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedOption: '',
      loading: false
    }
  },
  components: {
    VueMultiselect
  },
  computed: {
    ...mapGetters(['items']),
    options () {
      return this.items.map(x => {
        return {
          id: x.id,
          name: x.relationships.item.attributes.name,
          ...x
        }
      })
    }
  },
  methods: {
    ...mapActions(['fetchItems', 'setLoadingState']),
    asyncFind (query) {
      this.loading = true
      this.setLoadingState(true)
      this.fetchItems({ query: query })
        .finally(() => {
          this.loading = false
          this.setLoadingState(false)
        })
    },
    addItemToOrder (originalItem) {
      const item = { ...originalItem }
      this.$emit('item-selected', item)
    }
  },
  created () {
    this.fetchItems({ query: '' })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
