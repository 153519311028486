<template>
  <div class="content hunts-content">
    <AdminHeader />
    <div class="search">
      <!--
      <div class="search-input">
        <input type="text" placeholder="Digite sua busca">
      </div>
      -->
      <button class="main-btn create-order" @click="showNewCompensationModal = true;  addBodyClass()">
        <p>Criar novo</p>
        <div></div>
      </button>
    </div>

    <div class="table-main table-list">
      <table class="table-separate">
        <thead>
          <th @click="sort('id')">
            <p>ID</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('amount')">
            <p>Valor em R$</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <!--<th>current_balance</th>-->
          <th @click="sort('user-name')">
            <p>Distribuidor</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('creator-name')">
            <p>Criado por</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div></th>
          <th @click="sort('reason')">
            <p>Motivo</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div></th>
        </thead>
        <tbody>
          <tr v-for="compensation in compensations" :key="compensation.id">
            <td>
              <span>{{ compensation.id }}</span>
            </td>
            <td v-if="compensation.attributes.amount < 0" class="negative_value">
              <span>
                - R$ {{ formatCurrency(compensation.attributes.amount * -1) }}
              </span>
            </td>
            <td  class="positive_value" v-else >
              <span>
                + R$ {{ formatCurrency(compensation.attributes.amount) }}
              </span>
            </td>
            <!--<td>{{ compensation.attributes.currentBalance }}</td>-->
            <td>
              <span>
                {{ compensation.relationships.user.attributes.name }}
              </span>
            </td>
            <td>
              <span>
                {{ compensation.relationships.creator.attributes.name}}
              </span>
            </td>
            <td>
              <span>
                {{compensation.attributes.reason}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>
  <ModalCreateCompensation :show="showNewCompensationModal" @close="showNewCompensationModal = false" />
</template>
<script>
import Footer from '@/components/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
import AdminHeader from '@/components/admin/AdminHeader.vue'
import ModalCreateCompensation from '@/components/admin/ModalCreateCompensation.vue'

export default {
  data () {
    return {
      showNewCompensationModal: false,
      searchQuery: '',
      currentPage: 1,
      sortColumn: null,
      sortDirection: 'asc'
    }
  },
  components: { AdminHeader, ModalCreateCompensation, Footer },
  computed: {
    ...mapGetters(['users', 'compensations', 'total_pages'])
  },
  methods: {
    ...mapActions(['setLoadingState', 'adminFetchCompensations', 'fetchUser']),
    async changePage (page) {
      // this.setLoadingState(true)
      this.currentPage = page
      await this.adminFetchCompensations({ page: this.currentPage })
      // this.setLoadingState(false)
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.adminFetchCompensations({ page: this.currentPage, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    formatCurrency (amount) {
      if (!amount) return '0,00'
      const value = (amount / 100).toFixed(2)
      const parts = value.split('.')
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      const decimalPart = parts[1]
      return `${integerPart},${decimalPart}`
    }
  },
  created () {
    this.fetchUser()
    this.adminFetchCompensations({ page: 1 })
  },
  watch: {
    total_pages (newVal, oldVal) {
      if (newVal < oldVal) {
        // Se new value é menor que o old value, resete para o old value
        this.$nextTick(() => {
          this.$store.commit('SET_TOTAL_PAGES', oldVal)
        })
      }
    }
  }
}
</script>
