<template>
  <div v-if="show" class="modal">
    <section class="popup-detail promo form">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title">
        <h1>Cadastrar</h1>
      </div>
      <div class="promo-form">
        <form action="" @submit.prevent="submitForm1">
          <div class="input-flex">
            <div class="input-wrap double">
              <label for="promo-name">Nome da promoção</label>
              <input type="text" id="promo-name" name="promo-name" placeholder="Digite o nome da promoção" v-model="promo.name">
            </div>
            <div class="discount-select-wrap custom-select double">
              <label for="discount-select">Desconto extra</label>
              <select v-model="promo.discount">
                <option value="" disabled selected>Selecione a porcentagem</option>
                <option v-for="discount in getExtraDiscounts()" :key="discount" :value="discount">
                {{ discount * 100 }}%</option>
              </select>
              <!-- <input type="number" v-model="promo.discount"> -->
            </div>
          </div>
          <div class="input-flex">
            <div class="start-date-select-wrap custom-select double">
              <label for="start-date-select">Data de início</label>
              <input type="datetime-local" v-model="promo.start_at" />
            </div>
            <div class="end-date-select-wrap custom-select double">
              <label for="end-date-select">Data de fim</label>
              <input type="datetime-local" v-model="promo.end_at" />
            </div>
          </div>
          <div class="select-items select-members">
            <p class="fake-label">Integrantes</p>
            <select class="multipleSelect" multiple v-model="selectedUsers">
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.attributes.name }} ({{ user.attributes.email }})
              </option>
            </select>
          </div>
        </form>
      </div>
      <div class="button-wrap">
        <!--<button class="main-link">Desativar promoção</button>-->
        <button class="main-btn edit" @click="submitForm1">Salvar</button>
      </div>
    </section>

  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
// import VueMultiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['show'],
  data () {
    return {
      loading: false,
      promo: {
        name: '',
        discount: '',
        start_at: '',
        end_at: '',
        users: []
      },
      selectedUsers: []
    }
  },
  components: {
    // VueMultiselect
  },
  computed: {
    ...mapGetters(['token', 'users'])
  },
  methods: {
    ...mapActions(['adminFetchUsers', 'setLoadingState', 'adminFetchPromos']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    getExtraDiscounts () {
      const str = '0.10,0.15,0.20,0.25'
      const arr = str.split(',').map(Number)
      return arr
    },
    async submitForm1 () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        formData.append('name', this.promo.name)
        formData.append('discount', this.promo.discount.toFixed(2))
        formData.append('start_at', this.promo.start_at)
        formData.append('end_at', this.promo.end_at)
        this.selectedUsers.forEach((userId, index) => {
          formData.append(`users[${index}]`, userId)
        })
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/sales`, formData)

        this.selectedUsers = []
        this.promo.name = ''
        this.promo.discount = null
        this.promo.start_at = ''
        this.promo.end_at = ''
        this.promo.users = []

        this.$notification.emit('flash', { message: 'Promoção criada com sucesso!', type: 'success' })
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao criar a promoção!', type: 'error' })
      } finally {
        this.adminFetchPromos({ page: 1 })
        this.setLoadingState(false)
        this.closeModal()
      }
    },
    addUserToPromo (addedUser) {
      const user = { ...addedUser }
      user.id = addedUser.id
      user.name = addedUser.name
      this.promo.users.push(user)
    },
    removeUser (user) {
      const index = this.promo.users.indexOf(user)
      if (index !== -1) {
        this.promo.users.splice(index, 1)
      }
    }
  },
  created () {
    this.adminFetchUsers({ page: 1, page_size: 10000 })
  },

  setup () {

  }
}

</script>
