<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'HomeTotal',
  setup () {
    const store = useStore()
    const userRole = computed(() => store.state.user_access)
    const router = useRouter()

    const redirectToRoleSpecificPage = (role) => {
      switch (role) {
        case 'client':
          return '/dashboard'
        case 'admin':
          return '/admin/dashboard'
        case 'logistics':
          return '/admin/compensation'
        default:
          return '/dashboard' // Página padrão para outras roles ou usuários não autenticados
      }
    }

    const redirectUser = () => {
      const roleSpecificPage = redirectToRoleSpecificPage(userRole.value)
      router.push(roleSpecificPage)
    }

    // Realize o redirecionamento quando a view HomeTotal é carregada
    redirectUser()

    return { userRole }
  }
}
</script>
