<template>
  <div v-if="show" class="modal">
    <section class="popup-detail promo summary view" v-if="promo.attributes">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title">
        <div class="title-wrap">
          <div class="name-status">
            <h1>{{promo.attributes.name}}</h1>
            <div class="status-wrap" :class="promo.attributes.trashed? 'inactive' : 'active'">
              <div class="status"></div>
              <span>{{promo.attributes.trashed ? 'Inativa' : 'Ativa'}}</span>
            </div>
          </div>
        </div>
        <ul class="breadcrumb">
          <li>
            <p>#{{promo.id}}</p>
          </li>
        </ul>
      </div>
      <div class="user-details details">
        <div class="user-data details-flex">
          <div class="extra-discount">
            <p>Desconto extra</p>
            <span>{{promo.attributes.discount * 100}}%</span>
          </div>
          <div class="start-date">
            <p>Data de início</p>
            <span>{{ $formattedDateWithoutHours(promo.attributes.startAt)}}</span>
          </div>
          <div class="end-date">
            <p>Data de fim</p>
            <span>{{ $formattedDateWithoutHours(promo.attributes.endAt)}}</span>
          </div>
        </div>
      </div>
      <div class="members-details details">
        <div class="members details-simple">
          <p>Integrantes</p>
          <div class="select-items-wrap">
            <div v-for="user in promo.relationships.users" :key="user.id" class="tag">
              <span>{{user.attributes.name}}</span>
            </div>
          </div>
        </div>
        </div>
        <div class="button-wrap">
          <!--<button class="main-link">Desativar promoção</button>-->
          <button class="main-btn edit" @click="editPromo">Editar</button>
        </div>

    </section>

  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['show', 'promo_data'],
  data () {
    return {
      promo: []
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.fetchPromo()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['adminFetchUsers', 'setLoadingState']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    editPromo () {
      this.$emit('close') // Fechar o modal de visualização
      this.$emit('edit') // Emitir evento de edição para o componente pai
    },
    // removeBodyClass () {
    //   document.body.classList.remove('modal-opened')
    // },
    async fetchPromo () {
      this.setLoadingState(true)

      if (this.promo_data.id) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/sales/${this.promo_data.id}`)
          this.promo = response.data.data
          console.log(response)
        } catch (error) {
          console.log(error)
        } finally {
          this.setLoadingState(false)
        }
      }
    }

  },
  created () {
    this.fetchPromo()
  },

  setup () {

  }
}

</script>
