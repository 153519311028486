<template>
  <div class="content hunts-content">
    <AdminHeader />
    <div class="new-codes">
      <h3>
        Equivalências recentemente atribuídas ({{ unconfirmedCodes.length }})
        <span @click="toggleAddingClass"></span>
      </h3>
      <div class="new-codes-body">
        <ul v-if="unconfirmedCodes.length > 0" class="new-codes-list">
          <li>
            <span>Produto Antares</span>
            <span>Produto Distribuidor</span>
            <span>Distribuidor</span>
            <span>Notas</span>
            <span>Data da criação</span>
            <span>OK?</span>
          </li>
          <li v-for="code in unconfirmedCodes" v-bind:key="code.id">
            <span>
              <label>
                {{ code.relationships.item.attributes.name }} / {{ code.relationships.item.attributes.code }}
              </label>
            </span>
            <span>
              <label>
                {{ code.attributes.name }} / {{ code.attributes.code }}
              </label>
            </span>
            <span>
              <label>
                {{ code.relationships.user.attributes.name }} / {{ code.relationships.user.attributes.codeErp }}
              </label>
            </span>
            <span class="invoices" v-if="code.relationships.invoices.data.length > 0">
              <template v-for="invoice in code.relationships.invoices.data" :key="invoice.id">
                <label v-if="invoice.attributes.status == 'approved'" @click="openEvaluateModal(invoice)">
                  #{{ invoice.id }}
                </label>
                <label v-else class="inactive" @click="openEvaluateModal(invoice)">
                  #{{ invoice.id }}
                </label>
              </template>
            </span>
            <span class="invoices" v-else>
              Ainda não utilizada
            </span>
            <span>
              <label>
                {{ formatDate(code.attributes.createdAt) }}
              </label>
            </span>
            <span>
              <button @click="confirmCode(code)">OK</button>
            </span>
          </li>
        </ul>
        <div v-else class="new-codes-empty">
          Nenhuma nova equivalência por enquanto.
        </div>
      </div>
    </div>
    <div class="table-main table-list">
      <table class="table-separate admin-codes">
        <thead>
          <!--<th>current_balance</th>-->
          <th @click="sort('user-name')">
            <p>Distribuidor</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('reason')">
            <p>Quantidade de códigos</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('reason')">
            <p>Última atualização</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td v-if="user.relationships.itemCodeCount > 0">
              <p>
                <a href="#" @click="showModal(parseInt(user.id)); addBodyClass()">
                  {{ user.attributes.name }}
                  <!-- {{ user.id }} -->
                </a>
              </p>
            </td>
            <td v-else>
              <p>{{ user.attributes.name }}</p>
            </td>
            <td>
              <p>{{ user.relationships.itemCodeCount }}</p>
            </td>
            <td>
              <template v-if="user.relationships.itemCodeCount > 0">
                <p>{{ formatDate(user.relationships.itemCode.attributes.updatedAt) }}</p>
              </template>
              <template v-else>
                <p>Sem atualizações</p>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>
  <ModalViewProducts :show="showProductList" :selectedUserId="selectedUserId" @close="showProductList = false" />
  <ModalEvaluateInvoice :isModalOpened="isEvaluateModalOpened" @update="handleModalUpdate" @close="isEvaluateModalOpened = false" :invoice_data="selectedInvoice" />
</template>
<script>
import Footer from '@/components/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
import AdminHeader from '@/components/admin/AdminHeader.vue'
import ModalViewProducts from '@/components/admin/ModalViewProducts.vue'
import ModalEvaluateInvoice from '@/components/admin/ModalEvaluateInvoice.vue'
import axios from 'axios'

export default {
  props: ['isModalOpened', 'hunt_data'],
  data () {
    return {
      showProductList: false,
      searchQuery: '',
      currentPage: 1,
      sortColumn: null,
      sortDirection: 'asc',
      unconfirmedCodes: [],
      selectedInvoice: [],
      isEvaluateModalOpened: false
    }
  },
  components: { AdminHeader, ModalViewProducts, Footer, ModalEvaluateInvoice },
  computed: {
    ...mapGetters(['users', 'total_pages'])
  },
  methods: {
    ...mapActions(['setLoadingState', 'adminFetchProductsList', 'fetchAdminCodes', 'fetchUser']),
    openEvaluateModal (invoice) {
      this.selectedInvoice = invoice
      this.isEvaluateModalOpened = true
    },
    async changePage (page) {
      // this.setLoadingState(true)
      this.currentPage = page
      await this.adminFetchProductsList({ page: this.currentPage })
      // this.setLoadingState(false)
    },
    handleModalUpdate () {
      this.getNotConfirmedCodes()
    },
    toggleAddingClass () {
      document.body.classList.toggle('news-codes-closed', this.isAdding)
    },
    formatDate (isoDateString) {
      if (!isoDateString) return ''
      const date = new Date(isoDateString)
      const formattedDate = new Intl.DateTimeFormat('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      }).format(date)
      return formattedDate
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.adminFetchProductsList({ page: this.currentPage, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    async getNotConfirmedCodes () {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/items/codes`, {
        params: {
          'filter[confirmed]': 0,
          include: 'invoices',
          'page[size]': 300
        }
      })
      this.unconfirmedCodes = response.data.data
    },
    confirmCode (item) {
      const formData = new FormData()
      formData.append('ids[]', item.id)
      formData.append('confirmed', 1)
      axios.post(`${process.env.VUE_APP_API_URL}/api/items/codes/confirmation`, formData)
        .then(response => {
          this.getNotConfirmedCodes()
          this.fetchAdminCodes({ sectionId: this.selectedUserId })
          this.$notification.emit('flash', {
            message: 'Equivalência confirmada.',
            type: 'success'
          })
        })
        .catch(error => {
          alert(error)
        })
    },
    showModal (userId) {
      this.selectedUserId = userId// Armazena o ID do distribuidor selecionado
      this.showProductList = true // Mostra o modal
    }
  },
  created () {
    this.fetchUser()
    this.adminFetchProductsList({ page: 1 })
    this.getNotConfirmedCodes()
  }
}
</script>
