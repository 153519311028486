<template>
  <div v-if="show" class="modal">
    <div class="popup-detail user data form">
      <button class="close small-btn" @click="closeModal">&times;</button>
      <div class="title">
        <h1>Criar usuário</h1>
      </div>
      <div>
        <form @submit.prevent="submitForm1">
          <div class="user-data-form">
            <form action="">
              <div class="input-flex">
                <div class="input-wrap double">
                  <label for="name">Nome completo</label>
                  <input type="text" id="name" name="name" placeholder="Digite o nome completo" v-model="user.name" />
                </div>
                <div class="input-wrap double">
                  <label for="email">E-mail</label>
                  <input type="email" id="email" name="email" placeholder="Digite o E-mail" v-model="user.email" />
                </div>
              </div>
              <div class="input-flex">
                <div class="role-select-wrap custom-select">
                  <label for="role">Cargo</label>
                  <select name="role" id="role" v-model="user.role">
                    <option value="" disabled selected>Selecione o cargo</option>
                    <option value="admin">Administrador</option>
                    <option value="client">Distribuidor</option>
                    <option value="logistics">Logística</option>
                  </select>
                </div>
                <div class="input-wrap double" v-if="user.role === 'client'">
                  <label for="code_erp">Código ERP</label>
                  <input type="text" id="code_erp" name="code_erp" placeholder="Digite o código" v-model="user.code_erp" />
                </div>
              </div>
              <div class="input-flex" v-if="user.role === 'client'">
                <div class="input-wrap double">
                  <label>Razão social</label>
                  <input type="text" v-model="store.name" id="store_name" name="store_name" placeholder="Digite a razão social" />
                </div>
                <div class="input-wrap double">
                  <label>CNPJ</label>
                  <input v-mask="'##.###.###/####-##'" type="text" v-model="store.cnpj" name="store_cnpj" id="store_cnpj" placeholder="Digite o CNPJ" />
                </div>
              </div>
              <div class="input-flex" v-if="user.role === 'client'">
                <div class="input-wrap double">
                  <label>Código ERP da Loja</label>
                  <input type="text" v-model="store.code_erp" name="store_code_erp" id="store_code_erp" placeholder="Digite o código" />
                </div>
                <div class="price-select-wrap custom-select">
                  <label for="price_list_id">Tabela de preços</label>
                  <select v-model="user.price_list_id" name="price_list_id" id="price_list_id">
                    <option value="" disabled selected>Selecione aqui</option>
                    <option :value="option.id" v-for="option in priceListOptions" :key="option.id">{{ option.attributes.name }}</option>
                  </select>
                </div>
              </div>
              <div class="input-flex" v-if="user.role === 'client'">
                <div class="additional-discount-select-wrap custom-select" v-if="user.role === 'client'">
                  <label for="additional-discount-select">Desconto adicional</label>
                  <select name="current_discount" id="current_discount" v-model="user.current_discount">
                    <option value="" disabled selected>Selecione a porcentagem</option>
                    <option v-for="option in extraDiscountOptions" :key="option" :value="option">{{ (option * 100).toFixed() }}%</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <button class="main-btn confirm-edit">Cadastrar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['show'],
  data () {
    return {
      // step: 1,
      createdUser: [],
      priceListOptions: [],
      extraDiscountOptions: [],
      // stores: [],
      loading: false,
      user: {
        name: '',
        email: '',
        role: '',
        code_erp: '',
        price_list_id: '',
        // main_discount: '',
        current_discount: ''
      },
      store: []
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created () {
    this.fetchPriceListOptions()
    this.fetchExtraDiscountOptions()
  },
  methods: {
    ...mapActions(['adminFetchUsers', 'setLoadingState']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    async fetchPriceListOptions () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/products/prices`)
        this.priceListOptions = response.data.data
      } catch (error) {
        console.error(error)
      }
    },
    async fetchExtraDiscountOptions () { // Método para buscar as opções de desconto adicional
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/settings/discounts`)
        this.extraDiscountOptions = response.data.data.attributes.extra // Armazenar apenas as opções de desconto adicional
      } catch (error) {
        console.error(error)
      }
    },
    async submitForm1 () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        formData.append('name', this.user.name)
        formData.append('email', this.user.email)
        formData.append('role', this.user.role)
        // Salvar nome da loja e CNPJ da loja
        if (this.user.role === 'client') {
          formData.append('code_erp', this.user.code_erp)
          const storeData = [
            { name: this.store.name },
            { cnpj: this.store.cnpj },
            { code_erp: this.store.code_erp }
          ]
          storeData.forEach(data => {
            const key = Object.keys(data)[0]
            let value = data[key]
            if (key === 'cnpj') {
              value = value.replace(/[^\d]/g, '')
            }
            formData.append(`store[${key}]`, value)
          })
          // descontos
          formData.append('price_list_id', this.user.price_list_id)
          // formData.append('main_discount', this.user.main_discount)
          formData.append('current_discount', this.user.current_discount)
        }
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/users`, formData, formData)
        this.createdUser = response.data.data
        await this.adminFetchUsers({ page: this.currentPage })
        // Limpe os campos do formulário
        this.user = {
          name: '',
          email: '',
          role: '',
          code_erp: '',
          price_list_id: '',
          // main_discount: '',
          current_discount: ''
        }
        this.store = {
          name: '',
          cnpj: '',
          code_erp: ''
        }
        this.closeModal()
        this.$notification.emit('flash', {
          message: 'Usuário cadastrado com sucesso',
          type: 'success'
        })
      } catch (error) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro ao cadastrar o usuário',
          type: 'error'
        })
        console.error(error)
      } finally {
        this.setLoadingState(false)
      }
    }
  },

  setup () {

  }
}

</script>
