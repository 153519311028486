<template>
  <div v-if="show" class="modal">
    <section class="popup-detail promo form">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title">
        <h1>Editar</h1>
      </div>
      <div class="promo-form">
        <form action="" @submit.prevent="submitForm1">
          <div class="input-flex">
            <div class="input-wrap double">
              <label for="promo-name">Nome da promoção</label>
              <input type="text" id="promo-name" name="promo-name" placeholder="Digite o nome da promoção"
                v-model="promo.attributes.name">
            </div>
            <div class="discount-select-wrap custom-select double">
              <label for="discount-select">Desconto extra</label>
              <select v-model="promo.attributes.discount">
                <option v-for="discount in getExtraDiscounts()" :key="discount" :value="discount.toFixed(2)">
                  {{ discount * 100 }}%
                </option>
              </select>
            </div>
          </div>
          <div class="input-flex">
            <div class="start-date-select-wrap custom-select double">
              <label for="start-date-select">Data de início</label>
              <input type="datetime-local" v-model="promo.attributes.startAt" />
            </div>
            <div class="end-date-select-wrap custom-select double">
              <label for="end-date-select">Data de fim</label>
              <input type="datetime-local" v-model="promo.attributes.endAt" />
            </div>
          </div>
          <div class="select-items select-members">
            <p class="fake-label">Integrantes</p>
            <select multiple class="multipleSelect" v-model="selectedUsers">
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.attributes.name }} ({{ user.attributes.email }})
              </option>
            </select>
          </div>
        </form>
      </div>
      <div class="button-wrap">
        <button class="main-link" @click="deactivatePromo">Desativar promoção</button>
        <button class="main-btn edit" @click="submitForm1">Salvar</button>
      </div>
    </section>

  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
// import VueMultiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['show', 'promo_data'],
  data () {
    return {
      loading: false,
      selectedUsers: [],
      promo: {
        attributes: {
          name: '',
          discount: '',
          startAt: '',
          endAt: ''
        },
        relationships: {
          users: {

          }
        }
      }

    }
  },
  watch: {
    show: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.fetchPromo()
        }
      }
    }
  },
  components: {
    // VueMultiselect
  },
  computed: {
    ...mapGetters(['token', 'users'])
  },
  methods: {
    ...mapActions(['adminFetchUsers', 'setLoadingState', 'adminFetchPromos']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    getExtraDiscounts () {
      const str = '0.10,0.15,0.20,0.25'
      const arr = str.split(',').map(Number)
      return arr
    },
    async fetchPromo () {
      this.setLoadingState(true)
      if (this.promo_data.id) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/sales/${this.promo_data.id}`)
          this.promo = response.data.data
          this.selectedUsers = this.promo.relationships.users.map(user => user.id)
        } catch (error) {
          console.log(error)
        } finally {
          this.setLoadingState(false)
        }
      }
    },
    async submitForm1 () {
      this.setLoadingState(true)

      try {
        const url = `${process.env.VUE_APP_API_URL}/api/admin/sales/${this.promo_data.id}`
        const headers = {
          Authorization: `${process.env.VUE_APP_BEARER_TOKEN}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        const body = {
          name: this.promo.attributes.name,
          discount: (this.promo.attributes.discount),
          start_at: this.promo.attributes.startAt,
          end_at: this.promo.attributes.endAt,
          users: this.selectedUsers
        }

        const response = await axios.patch(url, body, { headers })

        // Verifique se a resposta foi bem-sucedida antes de prosseguir
        if (response.status !== 200) {
          throw new Error(`Erro na solicitação PATCH: ${response.statusText}`)
        }
        await this.adminFetchPromos({ page: 1 })
        this.$notification.emit('flash', { message: 'Promoção editada com sucesso', type: 'success' })
        this.closeModal()
        document.body.classList.remove('modal-opened')
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao editar a promoção', type: 'error' })
        console.error(error)
      } finally {
        this.setLoadingState(false)
      }
    },
    async deactivatePromo () {
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/admin/sales/${this.promo_data.id}`)
        await this.adminFetchPromos({ page: 1 })
        this.$notification.emit('flash', { message: 'Promoção desativada com sucesso', type: 'success' })
        this.closeModal()
        document.body.classList.remove('modal-opened')
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao desativar a promoção', type: 'error' })
        console.error(error)
      } finally {

      }
    }
  },
  created () {
    this.fetchPromo()
    this.adminFetchUsers({ page: 1, page_size: 10000 })
  },

  setup () {

  }
}

</script>
