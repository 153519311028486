<template>
  <div class="content hunts-content">
    <UserHeader />
    <div class="data-info">
      <!--
      <div class="info-block progress">
        <h2>
          Descontos
          <div>
            <span>
              <strong class="me"></strong>Desconto médio das suas vendas
            </span>
            <span>
              <strong class="you"></strong>Desconto liberado para seus pedidos Antares
            </span>
          </div>
        </h2>
        <div class="progress-bar">
          <div class="fill-color" :style="{ width: bigNumbers.attributes.averageDiscount * 100 * (bigNumbers.attributes.discountsTrack.length - 1) + '%' }"></div>
          <div v-for="discountTrack in bigNumbers.attributes.discountsTrack" :key="discountTrack" class="percent"
            :class="bigNumbers.attributes.currentDiscount === discountTrack ? 'filled' : ''">
            <p>{{ discountTrack * 100}}%</p>
          </div>
        </div>
        <p>Seu desconto atual de {{ bigNumbers.attributes.averageDiscount * 100 }}% será atualizado de acordo com os descontos que você aplicar.</p>
      </div>
      -->
      <div class="info-block available-balance">
        <h2>Saldo disponível</h2>
        <h1>R$ {{ $formatCentsToCurrency(bigNumbers.attributes.availableBalance) }}</h1>
        <p v-if="bigNumbers.attributes.creditsQty == 0">
          Nenhum upload de nota realizado
        </p>
        <p v-else>{{ bigNumbers.attributes.creditsQty }} upload<template v-if="bigNumbers.attributes.creditsQty > 1">s</template> de nota realizado<template v-if="bigNumbers.attributes.creditsQty > 1">s</template>
        </p>
      </div>
      <div class="info-block economy">
        <h2>Desconto disponível</h2>
        <h1>
          {{ bigNumbers.attributes.currentDiscount * 100 }}%
        </h1>
        <p>Para você usar em seus pedidos</p>
      </div>
      <div class="info-block economy">
        <h2>Economia</h2>
        <h1>R$ {{ $formatCentsToCurrency(bigNumbers.attributes.savedAmount) }}</h1>
        <p v-if="bigNumbers.attributes.debitsQty == 0">
          Sem pedidos realizados até agora
        </p>
        <p v-else>Através de {{ bigNumbers.attributes.debitsQty }} pedido<template v-if="bigNumbers.attributes.debitsQty > 1">s</template> realizado<template v-if="bigNumbers.attributes.debitsQty > 1">s</template>
        </p>
      </div>
    </div>
    <div class="table-main table-list hunts-table">
      <div class="title-btn-wrap">
        <h2>Extrato</h2>
        <button class="main-btn upload-note" @click="openUploadInvoiceModal">
          <p>Subir nota</p>
          <div></div>
        </button>
      </div>
      <table class="table-separate">
        <tr>
          <th @click="sort('amount')">
            <p>Valor</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th>
            <p>Descrição</p>
            <div>
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('updated')">
            <p>Data</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </tr>
        <tr v-for="statement in statements" v-bind:key="statement.id">
          <td class="numbers-balance">
            <p :class="statement.attributes.type === 'debit' || statement.attributes.type === 'debit_admin' ? 'less' : 'added'">
              <template v-if="statement.attributes.model == 'invoices' && statement.attributes.type === 'debit'">
                - R$ {{ $formatCentsToCurrency(statement.attributes.amount) }}
              </template>
              <template v-if="statement.attributes.model == 'invoices' && statement.attributes.type === 'debit_admin'">
                - R$ {{ $formatCentsToCurrency(statement.attributes.amount) }}
              </template>
              <template v-else>
                {{ statement.attributes.type === 'debit'
              ? '-' : '' }}
                R$ {{ statement.attributes.type === 'debit' ? $formatCentsToCurrency(-statement.attributes.amount) : $formatCentsToCurrency(statement.attributes.amount) }}
              </template>
            </p>
          </td>
          <td class="description">
            <p v-if="statement.attributes.model == 'invoices'" @click="openDetailInvoiceModal(statement.relationships.invoices.links.self)">
              <template v-if="statement.attributes.type === 'debit'">
                Cancelamento da nota <strong>{{ statement.attributes.value }}</strong>
              </template>
              <template v-if="statement.attributes.type === 'debit_admin'">
                Rejeição da nota <strong>{{ statement.attributes.value }}</strong>
              </template>
              <template v-if="statement.attributes.type === 'credit'">
                Upload da nota <strong>{{ statement.attributes.value }}</strong>
              </template>
            </p>
            <p v-if="statement.attributes.model == 'compensations'">
              Compensação. Motivo: {{ statement.attributes.value }}
            </p>
            <p v-if="statement.attributes.model == 'orders'" @click="showOrderDetails(statement.relationships.orders.links.self)">
              Desconto no pedido <strong>#{{ statement.relationships.orders.attributes.id }}</strong>
            </p>
          </td>
          <td>
            <p>
              {{ $formattedDateWithoutHours(statement.attributes.createdAt) }}
            </p>
            <span>
              {{ $formattedDateOnlyHours(statement.attributes.createdAt) }}
            </span>
          </td>
        </tr>

      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>
      <ModalUploadInvoice @close-modal="handleModalClose" />
      <ModalSetItemRelationship />
      <ModalDetailInvoice :show="showDetailInvoiceModal" @close="showDetailInvoiceModal = false" :invoiceLink="invoiceLink"/>
      <ModalOrderDetail v-if="showOrderDetailsModal" :order="selectedOrder" @close="selectedOrder = null" />
  <router-view />
</template>

<script>
import UserHeader from '@/components/client/UserHeader.vue'
import ModalUploadInvoice from '@/components/client/ModalUploadInvoice.vue'
import Footer from '@/components/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import ModalSetItemRelationship from '@/components/client/ModalSetItemRelationship.vue'
import ModalDetailInvoice from '@/components/client/ModalDetailInvoice.vue'
import ModalOrderDetail from '@/components/client/ModalOrderDetail.vue'

export default {
  components: {
    UserHeader,
    Footer,
    ModalUploadInvoice,
    ModalSetItemRelationship,
    ModalDetailInvoice,
    ModalOrderDetail
  },
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      isUploadModalOpened: false,
      bigNumbers: {
        attributes: {
          discountTrack: []
        }
      },
      sortColumn: null,
      sortDirection: 'asc',
      invoiceLink: '',
      showDetailInvoiceModal: false,
      showOrderDetailsModal: false,
      selectedOrder: []
    }
  },
  computed: {
    ...mapGetters(['statements', 'total_pages', 'user', 'modal_upload_invoice_opened'])
  },
  methods: {
    ...mapActions(['logout', 'fetchStatements', 'fetchUser', 'setLoadingState', 'setModalUploadInvoiceOpened', 'isLoggedIn', 'setNfeItems', 'setModalSetItemRelationshipOpened']),
    openUploadInvoiceModal () {
      this.setNfeItems([])
      this.setModalSetItemRelationshipOpened(false)
      this.setModalUploadInvoiceOpened(true)
    },
    async fetchBigNumbers () {
      this.setLoadingState(true)
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/bignumbers`)
        this.bigNumbers = response.data.data
      } catch (error) {
        this.$notification.emit('flash', { message: 'Houve um erro ao buscar seus dados', type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.fetchStatements({ page: this.currentPage, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    },
    changePage (page) {
      this.currentPage = page
      this.fetchStatements({ page: this.currentPage, query: this.searchQuery })
    },
    openDetailInvoiceModal (link) {
      this.invoiceLink = link
      this.showDetailInvoiceModal = true
    },
    handleModalClose () {
      console.log('Modal fechada, atualizando big numbers...')
      this.fetchBigNumbers()
    },
    async showOrderDetails (orderLink) {
      try {
        const orderDetails = await axios.get(orderLink)
        this.selectedOrder = Object.assign({}, orderDetails.data.data)
        this.showOrderDetailsModal = true
      } catch (e) {
        console.log(e)
      }
    }
  },
  created () {
    this.fetchBigNumbers()
    this.fetchUser()
    this.fetchStatements({ page: 1 })
  }
}
</script>
