<template>
  <div v-if="isModalOpened" class="modal">
  <section class="popup-detail approve-hunt">
    <button class="small-btn close" @click="closeModal"></button>
    <div class="title-wrap">
      <div class="name-status">
        <h1>{{ hunt_data.attributes.name }}</h1>
      </div>
      <!--
      <div class="used-promo">
        <p>+??FALTA% OFF</p>
        <span>Promoção extra</span>
      </div>
      -->
    </div>
    <pre>
      {{ bigNumbers }}
    </pre>
    <div class="hunt-discount">
      <div class="balance-discount">
        <div class="bd-block balance">
          <h3>Saldo</h3>
          <p>R$ {{ bigNumbers && $formatCentsToCurrency(bigNumbers.attributes.availableBalance) }}</p>
          <span>Saldo atual</span>
        </div>
        <div class="bd-block balance">
          <h3>&nbsp;</h3>
          <p>{{ bigNumbers && bigNumbers.attributes.creditsQty}}</p>
          <span>Notas upadas</span>
        </div>
        <div class="bd-block debit">
          <h3>Solicitações</h3>
          <p>{{ bigNumbers && bigNumbers.attributes.debitsQty}}</p>
          <span>Pedidos realizados</span>
        </div>
        <div class="bd-block debit">
          <h3>&nbsp;</h3>
          <p>R$ {{ bigNumbers && $formatCentsToCurrency(bigNumbers.attributes.savedAmount) }}</p>
          <span>Utilização de caçada</span>
        </div>
        <div class="bd-block discount">
          <h3>Descontos</h3>
          <p>{{ bigNumbers && (bigNumbers.attributes.currentDiscount * 100)}}% OFF</p>
          <span>Ao solicitar pedidos</span>
        </div>
        <div class="bd-block discount">
          <h3>&nbsp;</h3>
          <p>{{ bigNumbers && Math.round(bigNumbers.attributes.averageDiscount * 100) }}% OFF</p>
          <span>Concedito em notas</span>
        </div>
      </div>
    </div>
    <div class="popup-table extract-table">
      <p class="table-title">Extrato</p>
      <table>
        <thead>
          <tr>
            <th>
              <p>Valor</p>
            </th>
            <th>
              <p>Descrição</p>
            </th>
            <th>
              <p>Data</p>
              <!-- <div class="sort-wrap">
                <button class="recent"></button>
                <button class="latest"></button>
              </div> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="statement in statements" :key="statement.id" class="added">
            <td class="numbers-balance">
              <p :class="statement.attributes.type === 'debit' || statement.attributes.type === 'debit_admin' ? 'less' : 'added'">
                {{ statement.attributes.type === 'debit' || statement.attributes.type === 'debit_admin' ? '-' : '' }}
                R$ {{ statement.attributes.type === 'debit' ? $formatCentsToCurrency(statement.attributes.amount).replace('-', '') : $formatCentsToCurrency(statement.attributes.amount) }}
              </p>
            </td>
            <td class="description">
              <p v-if="statement.attributes.model == 'invoices'" @click="openEvaluateModal(statement.relationships.invoices.attributes)">
                <teamplate v-if="statement.attributes.type == 'debit'">
                  Cancelamento da nota <strong>{{ statement.attributes.value }}</strong>
                </teamplate>
                <teamplate v-if="statement.attributes.type == 'credit'">
                  Upload da nota <strong>{{ statement.attributes.value }}</strong>
                </teamplate>
                <teamplate v-if="statement.attributes.type == 'debit_admin'">
                  Rejeição da nota <strong>{{ statement.attributes.value }}</strong>
                </teamplate>
              </p>
              <p v-if="statement.attributes.model == 'compensations'">
                Compensação. Motivo: {{ statement.attributes.value }}
              </p>
              <p v-if="statement.attributes.model == 'orders'" @click="showOrderDetails(statement.relationships.orders.links.self)">
                Desconto aplicado no pedido #{{ statement.relationships.orders.attributes.id }}
              </p>
            </td>
            <td>
              <p>
                {{ $formattedDateWithoutHours(statement.attributes.createdAt) }}
                {{ $formattedDateOnlyHours(statement.attributes.createdAt) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ totalPages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= totalPages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    </div>
    <ModalEvaluateInvoice :isModalOpened="isEvaluateModalOpened" @close="isEvaluateModalOpened = false" :invoice_data="selectedInvoice" />
  </section>
</div>
  </template>
<script>
import axios from '../../config/axiosConfig.js'
import ModalEvaluateInvoice from './ModalEvaluateInvoice.vue'
import { mapActions } from 'vuex'

export default {
  props: ['isModalOpened', 'hunt_data'],
  data () {
    return {
      hunts: [],
      statements: [],
      selectedInvoice: [],
      isEvaluateModalOpened: false,
      currentPage: 1,
      bigNumbers: null
    }
  },
  components: { ModalEvaluateInvoice },
  watch: {
    isModalOpened: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.fetchStatements({ page: 1 })
        }
      }
    },
    isEvaluateModalOpened: {
      immediate: true,
      handler (newVal, oldVal) {
        this.fetchStatements({ page: 1 })
      }
    }
  },
  computed: {

  },
  methods: {
    ...mapActions(['setLoadingState']),
    openEvaluateModal (invoice) {
      this.selectedInvoice = invoice
      this.isEvaluateModalOpened = true
    },
    closeModal () {
      this.$emit('closeModal')
    },
    changePage (page) {
      this.currentPage = page
      this.fetchStatements({ page: this.currentPage })
    },
    async fetchStatements () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/users/statements`, {
          params: {
            'filter[user]': this.hunt_data.relationships.users.data.id,
            'page[number]': this.currentPage,
            'page[size]': 30,
            sort: '-updated'
          }
        })
        this.statements = response.data.data
        this.totalPages = response.data.meta.last_page

        const responseBigNumbers = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/users`, {
          params: {
            'filter[id]': this.hunt_data.relationships.users.data.id,
            include: 'bigNumbers'
          }
        })
        this.bigNumbers = responseBigNumbers.data.data[0].relationships.bigNumbers
        // const userData = await axios.get(`${this.hunt_data.relationships.users.data.links.self}`)
        // this.userData = userData.data
      } catch (e) {
        console.log(e)
      }
    }
  },
  created () {
    this.fetchStatements({ page: 1 })
  },
  onMounted () {
    this.fetchStatements({ page: 1 })
  }

}
</script>
