<template>
  <div class="content dash-content">
    <AdminHeader />
    <div class="dash-tables">
      <UsersList :users="users" />
      <InvoicesList />
      <ActivitiesList :activities="adminactivities"/>
    </div>
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import AdminHeader from '../../components/admin/AdminHeader.vue'
import UsersList from '../../components/admin/UsersList.vue'
import InvoicesList from '../../components/admin/InvoicesList.vue'
import ActivitiesList from '@/components/admin/ActivitiesList'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    Footer,
    UsersList,
    AdminHeader,
    InvoicesList,
    ActivitiesList
  },
  computed: {
    ...mapGetters(['adminactivities'])
  },
  methods: {
    ...mapActions(['fetchAdminActivities', 'fetchUser'])
  },
  created () {
    this.fetchUser()
    this.fetchAdminActivities()
  }

}

</script>
