<template>
  <div style="padding: 50px;">
    <h1>Change Data</h1>
    <button @click="updateUser">Bloquear usuário 30</button>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <button @click="updatePromo">Alterar promoção 1</button>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <button @click="deletePromo">Deletar promoção 1</button>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <button @click="addCode">Adicionar código ao usuário</button>
    <button @click="removeCode(9)">Remover código 9 do 31</button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  methods: {
    async updateUser () {
      console.log('Método addCode chamado')
      try {
        const userId = 30 // ID do usuário que você deseja excluir
        const url = `${process.env.VUE_APP_API_URL}/api/admin/users/${userId}`
        const headers = {
          Authorization: `${process.env.VUE_APP_BEARER_TOKEN}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        const body = {
          status: 'active'
        }

        const response = await axios.patch(url, body, { headers })

        // Verifique se a resposta foi bem-sucedida antes de prosseguir
        if (response.status !== 200) {
          throw new Error(`Erro na solicitação PATCH: ${response.statusText}`)
        }

        alert(`Usuário ${userId} alterado com sucesso!`)
      } catch (error) {
        // Lidar com erros, por exemplo, exibir uma mensagem de erro
        console.error(`Ocorreu um erro ao excluir o usuário: ${error.message}`)
      }
    },
    async updatePromo () {
      try {
        const promoId = 1
        const url = `${process.env.VUE_APP_API_URL}/api/admin/sales/${promoId}`
        const headers = {
          Authorization: `${process.env.VUE_APP_BEARER_TOKEN}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        const body = {
          name: 'Edit name #',
          start_at: '2027-10-10 00:00:00'
        }

        const response = await axios.patch(url, body, { headers })

        // Verifique se a resposta foi bem-sucedida antes de prosseguir
        if (response.status !== 200) {
          throw new Error(`Erro na solicitação PATCH: ${response.statusText}`)
        }

        alert(`Usuário ${promoId} alterado com sucesso!`)
      } catch (error) {
        // Lidar com erros, por exemplo, exibir uma mensagem de erro
        console.error(`Ocorreu um erro ao excluir o usuário: ${error.message}`)
      }
    },
    async deletePromo () {
      try {
        const promoId = 1
        const url = `${process.env.VUE_APP_API_URL}/api/admin/sales/${promoId}`
        const headers = {
          Authorization: `${process.env.VUE_APP_BEARER_TOKEN}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        const body = {
          trashed: true,
          name: 'Mudando status para true'
        }

        const response = await axios.delete(url, body, { headers })

        // Verifique se a resposta foi bem-sucedida antes de prosseguir
        if (response.status !== 200) {
          throw new Error(`Erro na solicitação PATCH: ${response.statusText}`)
        }

        alert(`Usuário ${promoId} alterado com sucesso!`)
      } catch (error) {
        // Lidar com erros, por exemplo, exibir uma mensagem de erro
        console.error(`Ocorreu um erro ao excluir o usuário: ${error.message}`)
      }
    },
    async addCode () {
      // this.setLoadingState(true)
      const codeUserId = 31
      console.log('Clicou de novo')
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/my/items/${codeUserId}/code`
        const body = {
          item_id: 9,
          code: '99888'
        }
        const response = await axios.post(url, body)
        if (response.status === 201) {
          console.log('Código adicionado com sucesso.', response.data)
          alert('Código adicionado!')
          // Faça algo com a resposta, se necessário
        } else {
          console.error('Erro ao adicionar código:', response.statusText)
          // Trate o erro conforme necessário
        }
      } catch (error) {
        console.error('Erro na requisição:', error)
        // Trate o erro conforme necessário
      }
    },
    async removeCode (userItemId) {
      // this.setLoadingState(true)
      // const userItemId = 12
      const codeUserId = 31
      console.log('Clicou em deletar')
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/my/items/${codeUserId}/code/${userItemId}`
        const body = { }
        const response = await axios.delete(url, body)
        if (response.status === 201) {
          console.log('Produto deletado, eu acho', response.data)
          alert('Código deletado!')
          // Faça algo com a resposta, se necessário
        } else {
          console.error('Erro ao deletar:', response.statusText)
          // Trate o erro conforme necessário
        }
      } catch (error) {
        console.error('Erro na requisição:', error)
        // Trate o erro conforme necessário
      }
    }

  }
}
</script>
