<template>
  <div>
    <div class="dashboard-grid">
        Desculpe pelo equívoco!

        <button @click="denyInvite">Confirmar recusa</button>
        <p v-if="message">{{ message }}</p>
        <button @click="acceptInvite">Na verdade quero aceitar o convite</button>
    </div>

  </div>
</template>

<script>
import axios from '../config/axiosConfig.js'

export default {
  data () {
    return {
      message: ''
    }
  },
  methods: {
    denyInvite () {
      const formData = new FormData()
      formData.append('token', this.$route.query.token)
      axios.post(`${process.env.VUE_APP_API_URL}/api/auth/block`, formData)
        .then(response => {
          this.message = 'Usuário bloqueado com sucesso.'
        })
        .catch(error => {
          this.message = 'Erro ao bloquear o usuário.'
          console.log(error)
        })
    },
    acceptInvite () {
      this.$router.push(`/definePassword?token=${this.$route.query.token}`)
    }
  }
}
</script>
