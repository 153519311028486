<template>
  <section class="main-page dashboard">
    <Notifications />
    <div v-if="isLoading">
      <GlobalLoader />
    </div>
    <Sidebar />
    <router-view />
  </section>
</template>

<script>
import Sidebar from '@/components/SideBar.vue'
import Notifications from '@/components/Notifications'
import GlobalLoader from '@/components/loaderGlobal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Sidebar,
    GlobalLoader,
    Notifications
  },
  computed: {
    ...mapGetters(['isLoading', 'isLoggedIn'])
  }

}
</script>

<style></style>
