<template>
  <div class="content orders-content">
    <UserHeader />
    <div class="search">
      <div class="search-input">
        <input type="text" placeholder="Digite sua busca" v-model="searchQuery" @keyup.enter="searchOrders">
      </div>
      <button class="main-btn create-order" @click="showNewOrderModal = true; addBodyClass()" v-if="user && user.relationships && user.relationships.price && user.relationships.price.attributes.name !== null">
        <p>Criar novo</p>
        <div></div>
      </button>
    </div>

    <div class="table-main table-list orders-table">
      <table class="table-separate">
        <tr>
          <!--
            <th>
              <input type="checkbox" name="order-check" id="order-check" class="check-all">
            </th>
          -->
          <th>
            <p>Status</p>
          </th>
          <th @click="sort('description')">
            <p>Descrição</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th>
            <p @click="sort('store-name')">Loja</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('updated-at')">
            <p>Atualização</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('created-at')">
            <p>Criação</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </tr>
        <tr v-for="order in orders" v-bind:key="order.id">
          <td>
            <div class="status-wrap" :class="order.attributes.status">
              <div class="status" :class="order.attributes.status">
                <template v-if="order.attributes.status == 'requested'">
                  Solicitado
                </template>
                <template v-if="order.attributes.status == 'sent'">
                  Aceito
                </template>
                <template v-if="order.attributes.status == 'approved'">
                  Aprovado
                </template>
                <template v-if="order.attributes.status == 'pending'">
                  Enviando
                </template>
                <template v-if="order.attributes.status == 'failed'">
                  Cancelado
                </template>
                <template v-if="order.attributes.status == 'cancelled'">
                  Cancelado
                </template>
                <template v-if="order.attributes.status == 'rejected'">
                  Rejeitado
                </template>
                <template v-if="order.attributes.status == 'blocked'">
                  Bloqueado
                </template>
                <template v-if="order.attributes.status == 'inactive'">
                  Desativado
                </template>
                <template v-if="order.attributes.status == 'completed'">
                  Concluído
                </template>
                <template v-if="order.attributes.status == 'finished'">
                  Concluído
                </template>
                <template v-if="order.attributes.status == 'onroute'">
                  A caminho
                </template>
                <template v-if="order.attributes.status == 'draft'">
                  Rascunho
                </template>
              </div>
            </div>
          </td>
          <td>
            <a v-if="order.attributes.description" class="main-link" @click="showOrderDetails(order)">{{ order.attributes.description }}</a>
            <a v-else class="main-link" @click="showOrderDetails(order)">Pedido sem descrição</a>
          </td>
          <td>
            <p>{{ order.relationships.store.attributes.name }}</p>
            <span>{{ order.relationships.store.attributes.cnpj }}</span>
          </td>
          <td>
            <p>{{ $formattedDateWithoutHours(order.attributes.updatedAt) }}</p>
            <span>{{ $formattedDateOnlyHours(order.attributes.updatedAt) }}</span>
          </td>
          <td>
            <p>{{ $formattedDateWithoutHours(order.attributes.createdAt) }}</p>
            <span>{{ $formattedDateOnlyHours(order.attributes.createdAt) }}</span>
          </td>
        </tr>

      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <Footer />
  </div>

  <ModalOrderDetail v-if="showOrderDetailsModal" :order="selectedOrder" @close="selectedOrder = null" />
  <ModalOrderCreate :show="showNewOrderModal" @close="showNewOrderModal = false" @submit="createOrder" />
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import ModalOrderDetail from '@/components/client/ModalOrderDetail.vue'
import ModalOrderCreate from '@/components/client/ModalOrderCreate.vue'
import UserHeader from '@/components/client/UserHeader.vue'

export default {
  components: {
    ModalOrderDetail,
    ModalOrderCreate,
    UserHeader,
    Footer
  },
  data () {
    return {
      selectedOrder: null,
      showNewOrderModal: false,
      showOrderDetailsModal: false,
      searchQuery: '',
      currentPage: 1,
      sortColumn: null,
      sortDirection: 'asc'
    }
  },
  computed: {
    ...mapGetters(['orders', 'hunts', 'user', 'total_pages'])
  },
  methods: {
    ...mapActions(['logout', 'fetchOrders', 'fetchUser', 'setLoadingState']),
    createOrder (newOrder) {
      // console.log(newOrder)
      this.showNewOrderModal = false
    },
    addBodyClass () {
      document.body.classList.add('modal-opened')
    },
    showOrderDetails (order) {
      this.selectedOrder = null
      this.$nextTick(() => {
        this.selectedOrder = Object.assign({}, order)
        this.showOrderDetailsModal = true
      })
      this.showOrderDetailsModal = true
    },
    searchOrders () {
      this.currentPage = 1
      this.fetchOrders({ page: this.currentPage, query: this.searchQuery })
    },
    async changePage (page) {
      this.setLoadingState(true)
      this.currentPage = page
      await this.fetchOrders({ page: this.currentPage, query: this.searchQuery })
      this.setLoadingState(false)
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.fetchOrders({ page: this.currentPage, query: this.searchQuery, sort: this.sortColumn, direction: this.sortDirection })
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    }
  },

  created () {
    this.fetchOrders({ page: this.currentPage })
  }
}
</script>
