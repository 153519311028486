<template>
  <div class="content hunts-content">
    <AdminHeader />
    <div class="search">
      <div class="search-input">
        <input type="text" placeholder="Digite sua busca">
      </div>
    </div>
    <div class="table-main table-list">
      <table class="table-separate">
        <tr>
          <!--
          <th>
            <input type="checkbox" name="order-check" id="order-check" class="check-all">
          </th>
          -->
          <th @click="sort('user-name')">
            <p>Distribuidor</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('credit')">
            <p>Saldo</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('updated-at')">
            <p>Atualização</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
          <th @click="sort('created-at')">
            <p>Criação</p>
            <div class="sort-wrap">
              <button class="recent"></button>
              <button class="latest"></button>
            </div>
          </th>
        </tr>
        <tr v-for="hunt in hunts" :key="hunt.id">
          <!--
          <td>
            <input type="checkbox" name="order-check" id="order-check" class="check-one">
          </td>
          -->
          <td class="link-over">
            <a class="main-link" href="/pages/distribuidor.html" @click.prevent="openApproveModal(hunt)">{{ hunt.attributes.name}}</a>
          </td>
          <td>
            <p>R$ {{ $formatCentsToCurrency(hunt.attributes.balance) }}</p>
          </td>
          <td v-if="hunt.attributes.updatedAt">
            <p>
              {{ isValidDate(hunt.attributes.updatedAt) ? $formattedDateWithoutHours(hunt.attributes.updatedAt) : "Sem atualização" }}
            </p>
            <span>
              {{ isValidDate(hunt.attributes.updatedAt) ? $formattedDateOnlyHours(hunt.attributes.updatedAt) : "--" }}
            </span>
          </td>
          <td v-else>
            <p>Sem atualizações</p>
            <span></span>
          </td>
          <td>
            <p>{{ $formattedDateWithoutHours(hunt.attributes.createdAt) }}</p>
            <span>{{ $formattedDateOnlyHours(hunt.attributes.createdAt) }}</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <button class="main-btn grey-btn previous-btn" :disabled="currentPage <= 1" @click="changePage(currentPage - 1)">
        <div></div>
        <p>Anterior</p>
      </button>
      <p>Página {{ currentPage }} de {{ total_pages }}</p>
      <button class="main-btn grey-btn next-btn" :disabled="currentPage >= total_pages"
        @click="changePage(currentPage + 1)">
        <div></div>
        <p>Próxima</p>
      </button>
    </div>
    <ModalApproveHunt :isModalOpened="isApproveModalOpened" @closeModal="isApproveModalOpened = false" :hunt_data="selectedHunt"/>
    <Footer />
  </div>

</template>
<script>
import Footer from '@/components/Footer.vue'
import AdminHeader from '@/components/admin/AdminHeader.vue'
import ModalApproveHunt from '@/components/admin/ModalApproveHunt.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['hunt_data'],
  data () {
    return {
      isApproveModalOpened: false,
      selectedHunt: [],
      currentPage: 1,
      sortColumn: null,
      sortDirection: 'asc',
      searchQuery: ''
    }
  },
  components: { AdminHeader, ModalApproveHunt, Footer },
  computed: {
    ...mapGetters(['hunts', 'total_pages'])
  },
  methods: {
    ...mapActions(['adminFetchHunts', 'fetchUser']),
    openApproveModal (hunt) {
      this.selectedHunt = hunt
      // Abre o modal de aprovação de caçada
      this.isApproveModalOpened = true
    },
    isValidDate (dateString) {
      const year = new Date(dateString).getFullYear()
      return year > 0
    },
    changePage (page) {
      this.currentPage = page
      this.adminFetchHunts({ page: this.currentPage })
    },
    sort (column) {
      this.sortColumn = column
      this.toggleDirection()
      this.adminFetchHunts({ page: this.currentPage, sort: this.sortColumn, direction: this.sortDirection })
    },
    toggleDirection () {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    }
  },
  created () {
    this.fetchUser()
    this.adminFetchHunts({ page: 1 })
  }
}
</script>
