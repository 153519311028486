<template>
  <div v-if="isModalOpened" class="modal">
    <section class="popup-detail see-note note">
      <button class="small-btn close" @click="closeModal"></button>
      <div class="title-wrap">
        <div class="name-status">
          <h1>Nota #{{invoice.id}}</h1>
          <div v-if="invoice.attributes.status == 'rejected'" class="status-wrap cancelled">
            <span>Rejeitada</span>
          </div>
          <div v-else class="status-wrap completed">
            <span>Aprovada</span>
          </div>
        </div>
        <div class="date-time">
          <p class="date">
            Upada em {{ $formattedDateWithoutHours(invoice.attributes.createdAt)}} às {{ $formattedDateOnlyHours(invoice.attributes.createdAt)}}
          </p>
        </div>
      </div>
      <div class="nfe-details">
        <div class="nfe-details-block">
          <label>Número/Série</label>
          <p>
            <template v-if="invoice.attributes.number">{{ invoice.attributes.number }}</template><template v-else>Número não identificado</template>/<template v-if="invoice.attributes.series">{{ invoice.attributes.series }}</template><template v-else>Série não identificada</template>
          </p>
        </div>
        <div class="nfe-details-block">
          <label>Localidade</label>
          <p>{{ invoice.attributes.municipality }} ({{ invoice.attributes.uf }})</p>
        </div>
        <div class="nfe-details-block">
          <label>Código</label>
          <p>{{ invoice.attributes.code }}</p>
        </div>
      </div>
      <div class="popup-table itens-table">
        <table>
          <thead>
            <tr>
              <th>
                <p>Qtd</p>
              </th>
              <th>
                <p>Itens</p>
              </th>
              <th>
                <div class="tooltip-text-wrapper">
                  <p>Desconto na nota</p>
                  <div class="tooltip">
                    <span class="tooltip-text">A ANTARES te devolve parte dos descontos que você oferece aos seus
                      clientes, ou seja, quanto mais desconto você oferece, mais saldo você ganha.
                      <div class="arrow"></div>
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <p>Custo</p>
              </th>
              <th>
                <p>Estorno</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="qtd">
                <p>{{ item.attributes.quantity}}</p>
              </td>
              <td>
                <p>{{ item.attributes.code}}</p>
              </td>
              <td>
                <div>
                  <!-- provisório retirar ao ajustar o endpoint -->
                  <p>{{ item.attributes.discount * 100 }}%</p>
                </div>
              </td>
              <td>
                <div>
                  <p>R${{ $formatCentsToCurrency(item.attributes.unitPrice) }}</p>
                </div>
              </td>
              <td>
                <p>R$ {{ $formatCentsToCurrency(item.attributes.refund) }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="payment-detail">
        <div class="total-blank"></div>
        <div class="total-wrap">
          <div class="total">
            <p>Total</p>
            <div>
              <h3>R$ {{  $formatCentsToCurrency(invoice.attributes.cashback) }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div v-if="invoice.attributes.status == 'rejected'" class="button-wrap">
        <button class="main-btn approve tooltip left" @click="approveInvoice">Reabilitar
        </button>
      </div>
      <div v-else class="button-wrap">
        <button class="main-link tooltip red right" @click="rejectInvoice">REJEITAR
        </button>
      </div>

    </section>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '../../config/axiosConfig.js'
export default {
  props: ['isModalOpened', 'invoice_data'],
  data () {
    return {
      invoice: {
        attributes: {
          number: ''
        }
      },
      items: []
    }
  },
  watch: {
    isModalOpened: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.fetchInvoiceData()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['setLoadingState']),
    closeModal () {
      this.$emit('close')
      this.$emit('update')
    },
    async fetchInvoiceData () {
      if (this.invoice_data.id) {
        this.setLoadingState(true)
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/invoices/${this.invoice_data.id}`, {
            params: {
              include: 'items'
            }
          })
          this.invoice = response.data.data
          this.items = response.data.data.relationships.items.data
        } catch (error) {
          console.log(error)
        } finally {
          this.setLoadingState(false)
        }
      }
    },
    async approveInvoice () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        formData.append('status', 'reopened')
        formData.append('_method', 'PATCH')
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/invoices/${this.invoice_data.id}`, formData)
        this.$notification.emit('flash', { message: 'Nota reabilitada', type: 'success' })
        this.closeModal()
      } catch (error) {
        this.$notification.emit('flash', { message: 'Problema ao aprovar a nota', type: 'error' })
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    },
    async rejectInvoice () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        formData.append('user', this.user)
        formData.append('status', 'rejected')
        formData.append('_method', 'PATCH')
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/invoices/${this.invoice_data.id}`, formData)
        this.$notification.emit('flash', { message: 'Nota rejeitada', type: 'success' })
        this.closeModal()
      } catch (error) {
        this.$notification.emit('flash', { message: 'Problema ao rejeitar a nota', type: 'error' })
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    }
  }
}
</script>
