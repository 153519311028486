export function formatCentsToCurrency (value) {
  return (value / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

export function formatCurrencyToCents (value) {
  return Math.round(parseInt(value) * 100)
}

export function formatDate (date) {
  const formattedDate = new Date(date)
  return ('0' + formattedDate.getDate()).slice(-2) + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear() + ' ' + formattedDate.getHours() + ':' + (formattedDate.getMinutes() < 10 ? '0' : '') + formattedDate.getMinutes()
}

export function formattedDateWithoutHours (date) {
  const formattedDate = new Date(date)
  return ('0' + formattedDate.getDate()).slice(-2) + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear()
}

export function formattedDateOnlyHours (date) {
  const formattedDate = new Date(date)
  return formattedDate.getHours() + ':' + (formattedDate.getMinutes() < 10 ? '0' : '') + formattedDate.getMinutes()
}
