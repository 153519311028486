<template>
    <div>
        <div class="user-warning" v-if="shouldShowWarning">
            <strong>A sua tabela de preços atual irá expirar em {{ formatDate(user.relationships.price.attributes.endAt) }}.</strong> Submeta seus pedidos em rascunho para garantir a aquisição com os preços atuais.
        </div>
        <div class="user-header">
            <div v-if="user.attributes" class="user-wrap">
                <div class="user" v-if="!isLoading">
                    <p>Olá, {{user.attributes.firstName}}</p>
                    <span></span>
                </div>
                <a href="#" class="logout" @click="logout">
                    <div></div>
                    <p>Sair</p>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['user', 'isLoggedIn', 'isLoading']),
    shouldShowWarning () {
      if (!this.user || !this.user.relationships) {
        return false
      }
      if (!this.user.relationships.price || !this.user.relationships.price.attributes) {
        return false
      }
      if (!this.user.relationships.price.attributes.endAt) {
        return false
      }
      const currentDate = new Date().toISOString().split('T')[0]
      const endAtDate = this.user.relationships.price.attributes.endAt
      const diffDays = this.calculateDateDifference(currentDate, endAtDate)
      return diffDays < 30
    }
  },
  methods: {
    ...mapActions(['logout']),
    calculateDateDifference (date1, date2) {
      const dt1 = new Date(date1)
      const dt2 = new Date(date2)
      const diffTime = Math.abs(dt2 - dt1)
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    },
    formatDate (date) {
      if (!date) return ''
      const formattedDate = new Date(date)
      return formattedDate.toLocaleDateString('pt-BR')
    }
  }
}
</script>
