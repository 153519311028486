
import axios from 'axios'
import router from '../router.js'
import store from '../store/index.js'

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers.Accept = 'application/json'
      // config.headers['Content-Type'] = 'application/json'
      if (config.data instanceof FormData) {
        // remove 'Content-Type' header to allow browser to set it automatically
        delete config.headers['Content-Type']
      } else {
        config.headers['Content-Type'] = 'application/json'
      }
    }
    return config
  },

  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default axios
