<template>
  <div class="user-header">
    <div class="view-promo new-promo">
        <button class="main-btn create-promo">
            <p>Nova promoção</p>
            <div></div>
        </button>
        <p>Crie rapidamente uma promoção</p>
    </div>
    <div class="view-promo current-promo">
        <div class="extra-promo">
            <div class="value-wrap">
                <span>Promoção extra</span>
                <p>10% OFF</p>
            </div>
        </div>
        <p><a href="/pages/edit-promo.html" class="main-link">Clique aqui</a> para editar a promoção</p>
    </div>
    <div v-if="user.attributes" class="user-wrap">
        <div class="user" v-if="!isLoading">
            <p>Olá, {{user.attributes.firstName}}</p>
            <span></span>
        </div>
        <a href="#" class="logout" @click="logout">
            <div></div>
            <p>Sair</p>
        </a>
    </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['user', 'isLoggedIn', 'isLoading'])
  },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>
