import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import './assets/base.css'
import './assets/css/main.css'
import './assets/css/overrides.css'
import './assets/css/multiselect.css'
import { formatCentsToCurrency, formatCurrencyToCents, formatDate, formattedDateWithoutHours, formattedDateOnlyHours } from './utils/utils.js'
import { notificationEmitter } from './utils/notificationEmitter.js'
import VueTheMask from 'vue-the-mask'

const app = createApp(App)

app.config.globalProperties.$formatCentsToCurrency = formatCentsToCurrency
app.config.globalProperties.$formatCurrencyToCents = formatCurrencyToCents
app.config.globalProperties.$formatDate = formatDate
app.config.globalProperties.$formattedDateWithoutHours = formattedDateWithoutHours
app.config.globalProperties.$formattedDateOnlyHours = formattedDateOnlyHours
app.config.globalProperties.$notification = notificationEmitter

app.use(store).use(router).use(VueTheMask).mount('#app')

// createApp(App).use(store).use(router).mount('#app')
