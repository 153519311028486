<template>
  <div v-if="message" class="notifications">
      <div class="notifications-wrap">
          <div :class="`message ${type}`">
              <div class="icon icon-success"></div>
              <p>{{message}}</p>
          </div>
      </div>
  </div>
</template>

<script>
import { notificationEmitter } from '../utils/notificationEmitter.js'

export default {
  data () {
    return {
      message: null,
      type: null
    }
  },
  mounted () {
    notificationEmitter.on('flash', this.showFlash)
  },
  beforeUnmount () {
    notificationEmitter.off('flash', this.showFlash)
  },
  methods: {
    showFlash ({ message, type }) {
      this.message = message
      this.type = type
      setTimeout(() => {
        this.message = null
      }, 5000)
    }
  }
}
</script>

<style>
.flash-message {
position: fixed;
top: 0;
left: 0;
width: 100%;
padding: 20px;
text-align: center;
z-index: 1000;
}
.flash-message.success {
background-color: #4caf50;
color: white;
}
.flash-message.error {
background-color: #f44336;
color: white;
}
</style>
