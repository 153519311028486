<template>
  <section class="popup login">
    <div class="logo">
      <div></div>
    </div>
    <div class="title-wrap">
      <h1>Faça o Login</h1>
      <p>Entre na plataforma para realizar pedidos, editá-los ou acompanhá-los.</p>
    </div>
    <div class="form-login">
      <form @submit.prevent="submit">
        <div class="input-wrap">
          <label for="email">E-mail</label>
          <input v-model="email" placeholder="Digite aqui" class="email" required />
        </div>
        <div class="input-wrap">
          <div class="eye-pass" :class="{ show: passwordFieldType === 'text' }"
            @click="togglePasswordVisibility"></div>
          <label for="password">Senha</label>
          <input v-model="password" placeholder="Digite aqui" :type="passwordFieldType" class="pass" required />
        </div>
        <a href="#" class="main-link forgot-pass" @click="recoverPassword">Esqueci a minha senha</a>
        <label for="remember-check" class="remember-login">
          <input type="checkbox" name="remember-check" id="remember-check">
          <p>Lembrar meu login</p>
        </label>
        <button type="submit" class="main-btn login-btn">Entrar</button>
      </form>
    </div>
    <div class="version-control">Versão 1.16 de 20 de dezembro de 2023, 18:25</div>
  </section>
  <section class="hidden-content">
    <div class="hidden-content-header">
      <div>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="hidden-content-content">
      <div class="hidden-content-content-left">
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
        <div class="hidden-content-content-left-block">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="hidden-content-content-right">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password'
    }
  },
  head () {
    return {
      title: 'Página de Login'
    }
  },
  methods: {
    ...mapActions(['login']),
    async submit () {
      try {
        await this.login({ email: this.email, password: this.password })
      } catch (err) {
        this.$notification.emit('flash', {
          message: 'Ocorreu um erro na tentativa de login.',
          type: 'error'
        })
      }
    },
    recoverPassword () {
      this.$router.push('/recoverPassword')
    },
    togglePasswordVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'text' ? 'password' : 'text'
    },
    restorePasswordVisibility () {
      this.passwordFieldType = 'password'
    }
  },
  created () {
    // console.log('Usuarios:')
    // console.log('admin@meeg.app - g0h0rse@m33g')
    // console.log('cliente@meeg.app - g0h0rse@m33g')
  }
}
</script>
