<template>
  <div v-if="show" class="modal">
    <div class="popup-detail user data form">
      <button class="close small-btn" @click="closeModal">&times;</button>
      <div class="title">
        <h1>Editar usuário</h1>
      </div>
      <div>
        <form @submit.prevent="updateUser">
          <div class="user-data-form">
            <div class="input-flex">
              <div class="input-wrap double">
                <label for="name">Nome completo</label>
                <input type="text" id="name" name="name" v-model="user.name" />
              </div>
              <div class="input-wrap double">
                <label for="email">E-mail</label>
                <input type="email" id="email" name="email" v-model="user.email" />
              </div>
            </div>
            <div class="input-flex">
              <div class="role-select-wrap custom-select disabled">
                <label for="role">Cargo</label>
                <select name="role" v-model="user.role">
                  <option value="admin">Administrador</option>
                  <option value="client">Distribuidor</option>
                  <option value="logistics">Logística</option>
                </select>
              </div>
              <div class="input-wrap double" v-if="user.role === 'client'">
                <label for="code_erp">Código ERP</label>
                <input type="text" id="code_erp" name="code_erp" v-model="user.codeErp" />
              </div>
            </div>
            <div class="input-flex" v-if="user.role === 'client'">
              <div class="input-wrap double">
                <label>Razão social</label>
                <input type="text" v-model="user.stores[0].attributes.name" />
              </div>
              <div class="input-wrap double">
                <label>CNPJ</label>
                <input v-mask="'##.###.###/####-##'" type="text" v-model="user.stores[0].attributes.cnpj" />
              </div>
            </div>
            <div class="input-flex" v-if="user.role === 'client'">
              <div class="input-wrap double">
                <label>Código ERP da Loja</label>
                <input type="text" v-model="user.stores[0].attributes.codeErp" />
              </div>
              <div class="price-select-wrap custom-select">
                <label for="price_list_id">Tabela de preços</label>
                <select name="price_list_id" v-model="user.price_list_id">
                  <option value="" disabled>Selecione aqui</option>
                  <option
                    :value="option.id"
                    v-for="option in priceListOptions"
                    :key="option.id"
                  >{{ option.attributes.name }}</option>
                </select>
              </div>
            </div>
            <div class="input-flex" v-if="user.role === 'client'">
              <div class="additional-discount-select-wrap custom-select">
                <label for="current_discount">Desconto adicional</label>
                <select name="current_discount" id="current_discount" v-model="user.discounts.current">
                  <option value="" disabled selected>Selecione a porcentagem</option>
                  <option v-for="option in extraDiscountOptions" :key="option" :value="option">{{ (option * 100).toFixed() }}%</option>
                </select>
              </div>
            </div>
          </div>
          <button class="main-btn confirm-edit">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../config/axiosConfig.js'
import { mapActions } from 'vuex'

export default {
  props: ['show', 'user_data', 'currentPage'],
  data () {
    return {
      priceListOptions: [],
      extraDiscountOptions: [],
      user: {
        name: '',
        email: '',
        role: '',
        codeErp: '',
        stores: [
          {
            attributes: {
              name: '',
              cnpj: '',
              codeErp: ''
            }
          }
        ],
        price_list_id: '',
        discounts: {
          // main: '',
          current: ''
        }
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          if (this.user_data.id) {
            this.fetchUser()
          }
        }
      }
    }
  },
  created () {
    this.fetchPriceListOptions()
    this.fetchExtraDiscountOptions()
  },
  methods: {
    ...mapActions(['adminFetchUsers', 'setLoadingState']),
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    async fetchPriceListOptions () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/products/prices`)
        this.priceListOptions = response.data.data
      } catch (error) {
        console.error(error)
      }
    },
    async fetchExtraDiscountOptions () { // Método para buscar as opções de desconto adicional
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/settings/discounts`)
        this.extraDiscountOptions = response.data.data.attributes.extra // Armazenar apenas as opções de desconto adicional
      } catch (error) {
        console.error(error)
      }
    },
    async fetchUser () {
      this.setLoadingState(true)
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/admin/users/${this.user_data.id}`
        )

        const userData = response.data.data.attributes
        const userRelationships = response.data.data.relationships

        this.user = {
          id: response.data.data.id,
          name: userData.name || '',
          email: userData.email || '',
          role: userData.role || '',
          codeErp: userData.codeErp || '',
          stores: [
            {
              attributes: {
                name: '',
                cnpj: '',
                codeErp: ''
              }
            }
          ],
          price_list_id: '',
          discounts: {
            // main: '',
            current: ''
          }
        }

        if (userData.role === 'client' && userRelationships) {
          const storeData = userRelationships.stores.data[0]
          if (storeData) {
            this.user.stores[0].attributes.name = storeData.attributes.name || ''
            this.user.stores[0].attributes.cnpj = storeData.attributes.cnpj || ''
            this.user.stores[0].attributes.codeErp = storeData.attributes.codeErp || ''
          }

          const priceData = userRelationships.price
          if (priceData) {
            this.user.price_list_id = priceData.id || ''
          }
        }
        if (userData.discounts) {
          // this.user.discounts.main = userData.discounts.main || ''
          this.user.discounts.current = userData.discounts.current || ''
        }
      } catch (error) {
        // Lidar com erros
      } finally {
        this.setLoadingState(false)
      }
    },
    async updateUser () {
      this.setLoadingState(true)
      try {
        const url = new URL(`${process.env.VUE_APP_API_URL}/api/admin/users/${this.user.id}`)
        const headers = {
          Authorization: `${process.env.VUE_APP_BEARER_TOKEN}`,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }

        const body = {
          name: this.user.name,
          email: this.user.email,
          role: this.user.role
        }

        if (this.user.role === 'client') {
          body.code_erp = this.user.codeErp
          body.price_list_id = this.user.price_list_id
          // body.main_discount = this.user.discounts.main
          body.current_discount = this.user.discounts.current
          const store = this.user.stores[0].attributes
          body.store = {
            name: store.name,
            cnpj: store.cnpj,
            code_erp: store.codeErp
          }
        } else {

        }

        const response = await axios.patch(url, body, { headers })
        // this.currentPage = 1
        await this.adminFetchUsers({ page: this.currentPage })

        // Verifique se a resposta foi bem-sucedida antes de prosseguir
        if (response.status !== 200) {
          throw new Error(`Erro na solicitação PATCH: ${response.statusText}`)
        }

        // Lidar com a resposta, por exemplo, exibir uma mensagem de sucesso
        this.$notification.emit('flash', {
          message: 'Usuário atualizado com sucesso',
          type: 'success'
        })
      } catch (error) {
        // Lidar com erros, por exemplo, exibir uma mensagem de erro
        this.$notification.emit('flash', {
          message: `Ocorreu um erro ao atualizar o usuário: ${error.message}`,
          type: 'error'
        })
        console.error(error)
      } finally {
        this.setLoadingState(false)
        this.closeModal()
      }
    }
  }
}
</script>
