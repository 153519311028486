<template>
  <div v-if="show" class="modal">
    <div class="popup-detail user data form">
      <button class="close small-btn" @click="closeModal">&times;</button>
      <h2 v-if="user && user[0]">
        Equivalências de {{ user[0].attributes ? user[0].attributes.name : 'Carregando...' }} #{{ user[0].id }}
      </h2>
      <div class="code-table">
        <div class="code-table-line">
          <div class="code-table-line-cellth">Produto Antares</div>
          <div class="code-table-line-cellth">Produto distribuidor</div>
          <div class="code-table-line-cellth">Notas</div>
          <div class="code-table-line-cellth">Status</div>
        </div>
        <div v-for="code in admincodes" :key="code.id" class="code-table-line">
          <div class="code-table-line-celltd">
            {{ code.relationships.item.attributes.name }}<br/>
            <em>Código {{ code.relationships.item.attributes.code }}</em>
          </div>
          <div class="code-table-line-celltd">
            {{ code.attributes.name }}<br/>
            <em>Código {{ code.attributes.code }}</em>
          </div>
          <div class="code-table-line-celltd" v-if="code.relationships.invoices.data.length > 0">
            <template v-for="invoice in code.relationships.invoices.data" :key="invoice.id">
              <label v-if="invoice.attributes.status == 'approved'">
                #{{ invoice.id }}
              </label>
              <label v-else class="inactive">
                #{{ invoice.id }}
              </label>
            </template>
          </div>
          <div class="code-table-line-celltd" v-else>
            Ainda não utilizada
          </div>
          <div class="code-table-line-celltd">
            <template v-if="code.attributes.confirmed == true">
              Confirmada
            </template>
            <template v-else>
              <button @click="confirmItem(code)">Confirmar</button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../config/axiosConfig.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean,
    selectedUserId: Number
  },
  data () {
    return {
      user: {}
    }
  },
  watch: {
    selectedUserId: 'fetchUserDetails'
  },
  computed: {
    ...mapGetters(['admincodes'])
  },
  methods: {
    ...mapActions(['fetchAdminCodes']),
    fetchUserDetails () {
      if (this.selectedUserId) {
        const apiUrl = process.env.VUE_APP_API_URL
        axios
          .get(`${apiUrl}/api/admin/users?filter[id]=${this.selectedUserId}`)
          .then((response) => {
            this.user = response.data.data
            this.fetchAdminCodes({ sectionId: this.selectedUserId })
            console.log('Admin Codes:', this.admincodes)
          })
          .catch((error) => {
            console.error('Erro ao buscar detalhes do usuário:', error)
          })
      }
    },
    closeModal () {
      console.log('Método closeModal foi chamado')
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    },
    confirmItem (item) {
      const formData = new FormData()
      formData.append('ids[]', item.id)
      formData.append('confirmed', 1)
      axios.post(`${process.env.VUE_APP_API_URL}/api/items/codes/confirmation`, formData)
        .then(response => {
          this.fetchAdminCodes({ sectionId: this.selectedUserId })
          this.$notification.emit('flash', {
            message: 'Equivalência confirmada.',
            type: 'success'
          })
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created () {
    // Remova este gancho de ciclo de vida se não precisar dele
  }
}
</script>
