<template>
  <div class="content dash-content">
    <UserHeader />
    <div class="dash-tables">
          <OrdersList />
          <HuntsList />
          <ActivitiesList :activities="activities" />
    </div>
    <Footer />
  </div>
  <ModalUploadInvoice />
      <ModalSetItemRelationship />
  <router-view />
</template>

<script>
import OrdersList from '@/components/client/OrdersList.vue'
import Footer from '@/components/Footer'
import { mapActions, mapGetters } from 'vuex'
import HuntsList from '@/components/client/HuntsList.vue'
import UserHeader from '@/components/client/UserHeader.vue'
import ActivitiesList from '@/components/client/ActivitiesList.vue'
import ModalUploadInvoice from '@/components/client/ModalUploadInvoice.vue'
import ModalSetItemRelationship from '@/components/client/ModalSetItemRelationship.vue'

export default {
  components: {
    OrdersList,
    HuntsList,
    UserHeader,
    ActivitiesList,
    Footer,
    ModalUploadInvoice,
    ModalSetItemRelationship
  },
  computed: {
    ...mapGetters(['user', 'activities'])
  },
  methods: {
    ...mapActions(['logout', 'fetchUser', 'fetchActivities'])
  },

  created () {
    this.fetchActivities()
  }
}
</script>
