<template>
  <div v-if="show" class="modal">
  <section v-if="nfeData.attributes" class="popup-detail invoice-detail" :class="{ opened: true }">
    <span class="close small-btn close" @click="closeModal">&times;</span>
    <pre>
      {{ nfeData }}
    </pre>
    <div class="title-wrap" v-if="nfeData.attributes.status" >
      <h1>
        Detalhes da nota #{{ nfeData.id }}
      </h1>
      <div class="status-wrap" :class="nfeData.attributes.status">
        <span v-if="nfeData.attributes.status == 'rejected'">Rejeitada</span>
        <span v-if="nfeData.attributes.status == 'pending'">Pendente</span>
        <span v-if="nfeData.attributes.status == 'approved'">Aprovada</span>
      </div>
    </div>
    <div class="nfe-details">
      <div class="nfe-details-block">
        <label>Número/Série</label>
        <p>
          <template v-if="nfeData.attributes.number">{{ nfeData.attributes.number }}</template><template v-else>Número não identificado</template>/<template v-if="nfeData.attributes.series">{{ nfeData.attributes.series }}</template><template v-else>Série não identificada</template>
        </p>
      </div>
      <div class="nfe-details-block">
        <label>Localidade</label>
        <p>{{ nfeData.attributes.municipality }} ({{ nfeData.attributes.uf }})</p>
      </div>
      <div class="nfe-details-block">
        <label>Código</label>
        <p>{{ nfeData.attributes.code }}</p>
      </div>
    </div>
    <div class="popup-table itens-table" v-if="nfeData">
      <table>
        <thead>
          <tr>
            <th>
              <p>Qtd</p>
            </th>
            <th>
              <p>Itens</p>
            </th>
            <th>
              <p>Desconto na nota</p>
            </th>
            <th>
              <p>Custo do item</p>
            </th>
            <th class="align-right">
              <p>Cashback</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in this.nfeData.relationships.items.data" :key="item.attributes.quantity">
             <td class="qtd">
              <p>{{ item.attributes.quantity }}</p>
            </td>
            <td>
              <p>{{ item.attributes.description }}</p>
            </td>
            <td>
              {{ item.attributes.discount * 100}}%
            </td>
            <td>
              R$ {{ $formatCentsToCurrency(item.attributes.unitCost) }}
            </td>
            <td class="align-right">
              R$ {{ $formatCentsToCurrency(item.attributes.refund) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="nfe-totals">
      R$ {{ $formatCentsToCurrency(nfeData.attributes.cashback) }}
    </div>
    <!-- <div class="payment-detail" v-if="nfeData">
      <div class="total-wrap">
        <div class="total">
          <p>Total</p>
          <div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</div>
</template>
<script>
import axios from 'axios'

export default {
  props: ['show', 'invoiceLink'],
  data () {
    return {
      isModalOpened: false,
      nfeData: []
    }
  },
  watch: {
    invoiceLink: {
      immediate: true,
      handler (newVal, oldVal) {
        this.fetchInvoiceData()
      }
    }
  },
  methods: {
    async fetchInvoiceData () {
      try {
        const response = await axios.get(this.invoiceLink)
        this.nfeData = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    closeModal () {
      this.$emit('close')
      document.body.classList.remove('modal-opened')
    }
  }
}
</script>
